import React, {useMemo, useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {networkIntegrationService} from "../../../api/services/networkIntegrationService";
import DeleteNetworkIntegration from "../DeleteNetworkIntegration";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import {EVERFLOW, NATIVEPATH, ULTRACART} from "../../../utils/constants";


const EditNetworkIntegrationModal = (
    {
        setSnack,
        handleClose,
        fetchNetworkIntegrations,
        networkIntegration,
        open
    }
) => {
    const [loading, setLoading] = useState(false)
    const networksWithoutAccount = [
        EVERFLOW,
        NATIVEPATH
    ]

    const networksWithAdditionalField1 = [
        ULTRACART,
    ]

    const additionalField1Labels = {
        [ULTRACART]: {
            label: "Login URL",
            helperText: "URL must include merchantId in parameters"
        }
    }

    const selectedNetworkIsWithAccount =!networksWithoutAccount.includes(networkIntegration.network.id)
    const selectedNetworkIsWithAdditionalField1 = networksWithAdditionalField1.includes(networkIntegration.network.id)

    const validationSchema = useMemo(() => {
        const schema = {
            api_key: yup.string().required('API key is required'),
            name: yup.string().required('Name is required'),
            external_account: yup.string(),
            additional_field_1: yup.string()
        };

        if (selectedNetworkIsWithAccount) {
            schema.external_account = schema.external_account.required('External account is required').min(1, 'Required');
        }

        if (selectedNetworkIsWithAdditionalField1){
            schema.additional_field_1 = yup.string().required('UltraCart Link required')
        }

        return yup.object().shape(schema);
    }, [selectedNetworkIsWithAccount]); // Depend on the boolean check for whether the selected network has an account

    // useForm hook with dynamic resolver
    const {formState, control, handleSubmit, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            api_key: networkIntegration.api_key,
            name: networkIntegration.name,
            external_account: networkIntegration.external_account,
            additional_field_1: networkIntegration.additional_field_1
        }
    });

    const onSuccess = (data) => {
        setLoading(true)

        networkIntegrationService.updateItem(
            networkIntegration.id,
            data.api_key,
            data.name,
            data.external_account,
            data.additional_field_1
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                fetchNetworkIntegrations()
            })
            .catch((error) => {
                console.debug(error)
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            sx={{overflow: "scroll",
            }}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Network Integration"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    {
                        selectedNetworkIsWithAccount &&
                        <Grid item xs={4}>
                            <ControlledTextField control={control} name={"external_account"}
                                                 label={"Account/Affiliate ID"}/>
                        </Grid>
                    }
                    <Grid item xs={selectedNetworkIsWithAccount ? 8 : 12}>
                        <ControlledTextField control={control} name={"api_key"} label={"API Key"}/>
                    </Grid>
                                                    {
                                    selectedNetworkIsWithAdditionalField1 &&
                                    <Grid item xs={12}>
                                        <ControlledTextField control={control} name={"additional_field_1"}
                                                             label={additionalField1Labels[networkIntegration.network.id].label}
                                            helperText={additionalField1Labels[networkIntegration.network.id].helperText}
                                        />
                                    </Grid>
                                }
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Network Integration"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditNetworkIntegrationModal);