import {useState, useEffect, useCallback} from 'react';
import {useDispatch} from "react-redux";
import {setSnack} from "../store/actions/snack";

const useApi = (apiFunc) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [initiated, setInitiated] = useState(false);

    const makeRequest = useCallback((args, onSuccess = null, onFailure = null) => {
        if (apiFunc) {
            setLoading(true)
                    console.debug("fetching DATA")
            apiFunc(args)
                .then(response => {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                })
                .catch(errorResponse => {
                    if (onFailure) {
                        onFailure(errorResponse);
                    } else {
                        dispatch(setSnack(errorResponse.message, "error"));
                    }
                })
                .finally(() => {
                    setLoading(false);
                    console.debug("fetched DATA")
                    setInitiated(true)
                });
        }
    }, [apiFunc])


    return {
        loading,
        makeRequest,
        initiated
    };
};

export default useApi;