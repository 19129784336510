import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ChevronLeft, ChevronRight, Settings} from "@mui/icons-material";
import {Divider, IconButton, Paper, useTheme} from "@mui/material";
import {connect, useDispatch} from "react-redux";
import {logoutAction} from "../../store/actions/auth";
import {removeSnack} from "../../store/actions/snack";
import jwt_decode from "jwt-decode";

function SettingsButton({token}) {
    const theme = useTheme()
    let tokenData = !!token ? jwt_decode(token) : {}
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>

            {/*<Paper sx={{backgroundColor: theme.palette.primary.main}}>*/}
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                        backgroundColor: theme.palette.primary.main,
                        "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.primary.dark }
                }}
                >
                    <Settings />
                </IconButton>
            {/*</Paper>*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} disabled>{tokenData.email}</MenuItem>
                <Divider/>
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={() => dispatch(logoutAction())}>Logout</MenuItem>
            </Menu>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
    };
};

export default connect(mapStateToProps)(SettingsButton);