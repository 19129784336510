import React from 'react';
import FormHelperText from "../../FormHelperText";

const ContentModalHelperText = () => {
    return (
        <FormHelperText>
            Use <b>{"{{offer}}"}</b> instead of the URL of the offer.
        </FormHelperText>
    );
};

export default ContentModalHelperText;