import {useState} from "react";

const usePaginatedData = () => {
    const [data, setData] = useState({
        items: [],
        total: 1,
        page: 1,
        per_page: 10,
        pages: 1,
        has_prev: false,
        has_next: false,
        prev_num: null,
        next_num: null,

    });

    return [data, setData]
}

export default usePaginatedData;