import React, {useEffect, useState} from 'react';
import {Box, Toolbar, styled} from "@mui/material";
import {Outlet, useLocation} from "react-router-dom";
import {drawerWidth} from "../utils/drawerSettings";
import CustomAppBar from "./NavigationBar/CustomAppBar";
import CustomDrawer from "./NavigationBar/CustomDrawer";


const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(2),
        overflow: "auto",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppLayout = () => {
    const [open, setOpen] = useState(() => {
        const savedOpenState = sessionStorage.getItem('drawerOpen');
        return savedOpenState === null ? true : savedOpenState === 'true';
    });

    // Update sessionStorage when open changes
    useEffect(() => {
        sessionStorage.setItem('drawerOpen', open);
    }, [open]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };


    return (
        <Box sx={{display: 'flex', width: "100%"}}>

            <Box
                component="nav"
                sx={{flexShrink: 1}}
            >

                {/*<CustomAppBar handleDrawerToggle={handleDrawerToggle} open={open}/>*/}
                <CustomDrawer open={open} handleDrawerToggle={handleDrawerToggle}/>

            </Box>
            <Main open={open}>

                <Outlet/>

            </Main>
        </Box>
    );
};

export default AppLayout;