import React from 'react';
import {Grid} from "@mui/material";
import {mailingListService} from "../../../api/services/mailingListService";
import {filtersElementsTypes} from "../../../utils/constants";
import {mailingListGroupItemService} from "../../../api/services/mailingListGroupItemService";

import useTable from "../../../hooks/useTable";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import useApi from "../../../hooks/useApi";
import Filters from "../../Filters/Filters";
import MultipleSelectButton from "../../MultipleSelectButton";
import {ArrowRight, Delete} from "@mui/icons-material";
import useData from "../../../hooks/useData";
import useSelectableData from "../../../hooks/useSelectableData";
import useDeleteData from "../../../hooks/useDeleteData";
import useDeleteMultipleData from "../../../hooks/useDeleteMultipleData";
import EnhancedTable from "../../Table/EnhancedTable";

const EditMailingListGroupItem = ({mailingListGroup}) => {
    const dispatch = useDispatch()

    const {
        items: mailingListGroupItems,
        itemsPagination: mailingListGroupItemsPagination,
        itemsSort: mailingListGroupItemsSort,
        itemsFilters: mailingListGroupItemsFilters,
        handleRefreshItems: handleRefreshMailingListGroupItems,
        fetchItemsApi: fetchMailingListGroupItemsApi,
    } = useData(
        (args) => mailingListGroupItemService.getItems(
            {
                ...args,
                mailing_lists_group_id: mailingListGroup.id
            }),
        {
            mailing_list_name: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
        },
        {},
        {},
    )

    const {
        items: availableMailingListGroupItems,
        itemsPagination: availableMailingListGroupItemsPagination,
        itemsSort: availableMailingListGroupItemsSort,
        itemsFilters: availableMailingListGroupItemsFilters,
        handleRefreshItems: handleRefreshAvailableMailingListGroupItems,
        fetchItemsApi: fetchAvailableMailingListGroupItemsApi,
    } = useData(
        (args) => mailingListService.fetchAvailableItems({
            ...args,
            excluded_mailing_list_group_id: mailingListGroup.id
        }),
        {
            mailing_list_name: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
        },
        {},
        {},
    )

    const {
        selectedItems: selectedMailingListGroupItems,
        handleSelectItem: handleSelectMailingListGroupItems,
        setSelectedItems: setSelectedMailingListGroupItems,
        handleClearSelectedItems: handleClearSelectedMailingListGroupItems
    } = useSelectableData()

    const {
        selectedItems: selectedAvailableMailingListGroupItems,
        handleSelectItem: handleSelectAvailableMailingListGroupItems,
        setSelectedItems: setSelectedAvailableMailingListGroupItems,
        handleClearSelectedItems: handleClearSelectedAvailableMailingListGroupItems
    } = useSelectableData()

    const {
        deleteItem: deleteMailingListGroupItem,
        deleteItemApi: deleteMailingListGroupItemApi,
        handleDeleteItem: handleDeleteMailingListGroupItem
    } = useDeleteData(
        mailingListGroupItemService.deleteItem,
        handleRefreshMailingListGroupItems,
        handleSelectMailingListGroupItems
    )

    const {
        deleteItems: deleteMailingListGroupItems,
        handleOpenDeleteMultipleItemsModal: handleOpenDeleteMultipleMailingListGroupItemsModal,
        handleCloseDeleteMultipleItemsModal: handleCloseDeleteMultipleMailingListGroupItemsModal,
        handleDeleteMultipleItems: handleDeleteMultipleMailingListGroupItems,
        deleteItemsApi: deleteMailingListGroupItemsApi,
    } = useDeleteMultipleData(
        mailingListGroupItemService.deleteItems,
        selectedMailingListGroupItems,
        handleRefreshMailingListGroupItems,
        setSelectedMailingListGroupItems
    )

    const mailingListGroupItemsTable = useTable(
        mailingListGroupItems,
        mailingListGroupItemsPagination,
        selectedMailingListGroupItems,
        handleSelectMailingListGroupItems
    )

    const availableMailingListGroupItemsTable = useTable(
        availableMailingListGroupItems,
        availableMailingListGroupItemsPagination,
        selectedAvailableMailingListGroupItems,
        handleSelectAvailableMailingListGroupItems
    )

    const addMailingListGroupItemAPI = useApi(mailingListGroupItemService.createItem)


    const handleAddAvailableMailingListGroupItem = (item) => {
        console.debug({
            mailing_list_id: item.id,
            mailing_list_group_id: mailingListGroup.id
        })
        addMailingListGroupItemAPI.makeRequest(
            {
                mailing_list_id: item.id,
                mailing_list_group_id: mailingListGroup.id
            },
            (response) => {
                dispatch(setSnack(response.message))
                handleRefreshMailingListGroupItems()
                handleRefreshAvailableMailingListGroupItems()
                setSelectedAvailableMailingListGroupItems([])
            })
    }

    const addMultipleAvailableMailingListGroupItemsApi = useApi(mailingListGroupItemService.createItems)

    const handleAddMultipleMailingListsGroupItems = () => addMultipleAvailableMailingListGroupItemsApi.makeRequest(
        {
            mailing_list_ids: selectedAvailableMailingListGroupItems,
            mailing_lists_group_id: mailingListGroup.id
        },
        (response) => {
            dispatch(setSnack(response.message))
            handleRefreshMailingListGroupItems()
            handleRefreshAvailableMailingListGroupItems()
            setSelectedAvailableMailingListGroupItems([])
        })

    const mailingListGroupItemsTableHeadCells = [
        {
            id: 'mailing_list.name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            nested: true
        },
        {
            action: handleDeleteMailingListGroupItem,
            icon: <Delete/>,
        }
    ];

    const availableMailingListGroupItemsTableHeadCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            nested: true
        },
        {
            action: handleAddAvailableMailingListGroupItem,
            icon: <ArrowRight/>,
        }
    ];

    return (
        <>
            <Grid xs={12} item container spacing={2}>
                <Grid item xs={6} spacing={2} container>
                    <Grid item xs={12}>
                        <Filters filters={availableMailingListGroupItemsFilters} sx={{maxWidth: "100%"}}/>
                    </Grid>

                    <Grid item xs={12}>
                        <MultipleSelectButton
                            alwaysVisible={true}
                            loading={addMultipleAvailableMailingListGroupItemsApi.loading}
                            selectedItems={selectedAvailableMailingListGroupItems}
                            onClearSelectedItems={handleClearSelectedAvailableMailingListGroupItems}
                            onDeleteMultipleItems={handleAddMultipleMailingListsGroupItems}
                            icon={<ArrowRight/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EnhancedTable
                            disableSelect={true}
                            table={availableMailingListGroupItemsTable}
                            headCells={availableMailingListGroupItemsTableHeadCells}

                            data={{
                                items: availableMailingListGroupItems,
                                sort: availableMailingListGroupItemsSort,
                                pagination: availableMailingListGroupItemsPagination,
                                api: fetchAvailableMailingListGroupItemsApi
                            }}
                            searchTerm={availableMailingListGroupItemsFilters.values[availableMailingListGroupItemsFilters.primaryFilter]}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} container spacing={2}>
                    <Grid item xs={12}>
                        <Filters filters={mailingListGroupItemsFilters} sx={{maxWidth: "100%"}}/>
                    </Grid>

                    <Grid item xs={12}>
                        <MultipleSelectButton
                            alwaysVisible={true}
                            loading={deleteMailingListGroupItemsApi.loading}
                            selectedItems={selectedMailingListGroupItems}
                            onClearSelectedItems={handleClearSelectedMailingListGroupItems}
                            onDeleteMultipleItems={handleDeleteMultipleMailingListGroupItems}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EnhancedTable
                            disableSelect={true}
                            table={mailingListGroupItemsTable}
                            headCells={mailingListGroupItemsTableHeadCells}

                            data={{
                                items: mailingListGroupItems,
                                sort: mailingListGroupItemsSort,
                                pagination: mailingListGroupItemsPagination,
                                api: fetchMailingListGroupItemsApi
                            }}
                            searchTerm={mailingListGroupItemsFilters.values[mailingListGroupItemsFilters.primaryFilter]}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default EditMailingListGroupItem;