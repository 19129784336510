import React from 'react';
import {Box, Button, Grid} from "@mui/material";
import SubmitButton from "../SubmitButton";

const CustomModalFooter = (props) => {
    return (
        <Grid container spacing={2} {...props} item xs={12}/>
    );
};

export default CustomModalFooter;