import React from 'react';
import {Box, CircularProgress, Grid} from "@mui/material";

const FullPageLoader = () => {
    return (
        <Grid
            container
            sx={{
                height: 'calc(100vh - 64px - 24px)', // Replace 64px with your navbar height
                minHeight: '100%', // Fallback for browsers that do not support viewport units
            }}
            direction={"column"}
            alignItems={"center"} // To center CircularProgress horizontally
            justifyContent={"center"} // To center CircularProgress vertically
        >
            <Grid item xs={1}>
                <CircularProgress sx={{margin: "auto"}}/>
            </Grid>
        </Grid>
    );
};

export default FullPageLoader;