import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {getNestedValue} from "../../utils/formatText";
import dayjs from "dayjs";
import EnhancedTableEmptyRows from "./EnhancedTableEmptyRows";

const CustomTableCell = ({labelId, headCell, itemIndex, item, searchTerm}) => {
    const theme = useTheme()
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textRef = useRef(null);

    const highlightSearchTerm = (text, searchTerm) => {
        if (!searchTerm || !text) return text;
        // console.debug(text)
        if (typeof text === 'string') {

            const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
            return (
                <span>
            {parts.map((part, index) =>
                part.toLowerCase() === searchTerm.toLowerCase()
                    ? <a key={index} style={{color: theme.palette.primary.main, fontWeight: 'bold'}}>{part}</a>
                    : part
            )}
        </span>
            );
        }

        return text
    };

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
            }
        };

        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [item]);

    if (!!headCell.action) {
        return (
            <TableCell
                padding="checkbox"
                key={itemIndex}
                sx={{width: theme.customTokens.defaultTableIconWidth}}
            >
                <IconButton onClick={() => headCell.action(item)}>
                    {headCell.icon}
                </IconButton>
            </TableCell>
        )
    } else if (headCell.element && headCell.combined) {
        return (
            <TableCell
                padding="checkbox"
                key={itemIndex}
                align={headCell.align ? headCell.align : 'left'}
                sx={{width: theme.customTokens.defaultTableIconWidth}}
            >
                {headCell.element(item, item[headCell.combined])}
            </TableCell>
        )
    } else if (headCell.element) {
        return (

            <TableCell

                padding={"checkbox"}
                key={itemIndex}
                align={headCell.align ? headCell.align : 'left'}
                sx={{width: theme.customTokens.defaultTableIconWidth}}
            >
                {headCell.element(item)}
            </TableCell>
        )
    } else {
        let val = item[headCell.id];
        if (headCell.numeric) {
            let num = parseFloat(val);

            if (isNaN(num)) {
                val = "-"; // If the value is not a number, set it to "-"
            } else {
                if (headCell.float) {
                    val = num.toFixed(2); // Format to two decimal places if float is true
                }

                val = parseFloat(val).toLocaleString(undefined, {minimumFractionDigits: headCell.float ? 2 : 0});
                if (headCell.trailingSign) {
                    val = val + headCell.trailingSign; // Append trailing sign if specified
                }
            }
        }
        if (headCell.nested) {
            val = getNestedValue(item, headCell.id)
            val = highlightSearchTerm(val, searchTerm);
        } else if (headCell.datetime) {
            val = dayjs(val).format('D/M/YY')
        } else if (headCell.time) {
            val = dayjs(val, 'HH:mm:ss').format('hh:mm A')
        } else if (headCell.fullDatetime) {
            val = dayjs(val).format('D/M/YY H:mm')
        } else if (headCell.boolean) {
            val = item[headCell.id] ? "true" : "false"
        } else {
            val = highlightSearchTerm(val, searchTerm);
        }

        if (itemIndex === 0 || headCell.highlight) {
            return (
                <TableCell
                    // component="th"
                    id={labelId}
                    scope="row"
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    key={itemIndex}
                    sx={{
                        width: headCell.width,
                    }}
                >
                    <Tooltip title={val} arrow disableHoverListener={!isOverflowed}>
                        <Typography variant={"tableHighlight"}
                                    ref={textRef}
                                    sx={{
                                        display: "block",
                                        width: "100%",
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}>
                            {val}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )
        } else {
            return (
                <TableCell
                    key={itemIndex}
                    align={headCell.align ? headCell.align : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sx={{
                        width: headCell.width,
                    }}
                >
                    <Tooltip title={val} arrow disableHoverListener={!isOverflowed}>
                        <Typography
                            variant={"table"}
                            ref={textRef}
                            // noWrap // Prevent wrapping
                            sx={{
                                display: "block",
                                width: "100%",
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {val}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )
        }
    }
};

const EnhancedTableBody = (
    {

        items,
        table,
        headCells,
        emptyRows,
        searchTerm,
        rowHeight,
        rowsPerPage,
        disableSelecting
    }
) => {
    const theme = useTheme()
    if (!rowHeight)
        rowHeight = theme.customTokens.defaultRowHeight
    // console.debug(searchTerm)


    let content

    if (items.length) {
        content = <>
            {items.map((item, itemIndex) => {
                const isItemSelected = table.isSelected(item);
                const labelId = `enhanced-table-checkbox-${itemIndex}`;

                return (
                    <TableRow
                        hover
                        disabled={true}
                        // onClick={(event) => console.debug("click")}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={itemIndex}
                        sx={{
                            height: rowHeight
                        }}
                    >
                        {disableSelecting ? null : <TableCell padding="checkbox">
                            <Checkbox
                                onClick={(event) => table.handleClick(item, event)}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                            />
                        </TableCell>}
                        {
                            headCells.map((headCell, itemIndex) => {

                                return <CustomTableCell
                                    headCell={headCell}
                                    item={item}
                                    itemIndex={itemIndex}
                                    searchTerm={searchTerm}
                                    labelId={labelId}
                                    key={itemIndex}
                                />
                            })
                        }
                    </TableRow>
                )
            })}
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: rowHeight * emptyRows,
                    }}
                >
                    <TableCell colSpan={headCells.length + (disableSelecting ? 0 : 1)}/>
                </TableRow>
            )}
        </>
    } else {
        content = <EnhancedTableEmptyRows headCells={headCells} rowHeight={rowHeight} rowsPerPage={rowsPerPage}
                                          disableSelecting={disableSelecting}/>
    }


    return (
        <TableBody>
            {content}

        </TableBody>
    )
};
export default EnhancedTableBody;