import React from 'react';
import {Box, Button, TableCell, TableFooter, TablePagination, TableRow, useTheme} from "@mui/material";
import TablePaginationActions from "../Table/TablePaginationActions";
import SubmitButton from "../SubmitButton";

const ManualScheduleTableFooter = ({
                                       handleSubmit,
                                       itemsNo,
    loadingCampaigns
}) => {
    const theme = useTheme()
    return (
        <Box sx={{backgroundColor: theme.customTokens.tableHighlightColor}} display="flex" justifyContent="flex-end"
             width="100%" padding={1}>
            <SubmitButton
                fullWidth={false}
                variant={"contained"}
                type={"button"}
                loading={loadingCampaigns}
                text={`Schedule ${itemsNo} Campaign(s)`}
                onSubmit={handleSubmit}
                disabled={!itemsNo}
            />
        </Box>
    );
};

export default ManualScheduleTableFooter;