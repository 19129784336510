import React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import {useWatch} from "react-hook-form";

const ContentCodeArea = ({control, name, setValue}) => {

    const code_content = useWatch({
        control,
        name
    })

    const onChange = (event) => {
        setValue(name, event.target.value, {shouldTouch: true, shouldDirty: true})
    }

    return (
        <CodeEditor
            value={code_content}
            language="html"
            placeholder="Write code here."
            onChange={onChange}
            padding={15}
            data-color-mode="light"
            style={{
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
        />

    );
};

export default ContentCodeArea;