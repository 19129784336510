import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            mailing_list_group_id: args.mailing_lists_group_id,
            page: args.page,
            mailing_list_name: args.mailing_list_name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        apiClient.get(endpoints.mailingListGroupItem, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const createItem = ({mailing_list_id, mailing_list_group_id}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();
        console.debug({
            mailing_list_id,
            mailing_list_group_id
        })
        const data = {
            mailing_list_id,
            mailing_list_group_id
        };

        apiClient.post(endpoints.mailingListGroupItem, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const createItems = ({mailing_list_ids, mailing_lists_group_id}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            mailing_list_ids: Object.keys(mailing_list_ids),
            mailing_list_group_id: mailing_lists_group_id
        };

        apiClient.post(endpoints.mailingListGroupItem + "/multiple", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(endpoints.mailingListGroupItem + "/" + itemId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.mailingListGroupItem + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

export const mailingListGroupItemService = {
    getItems,
    createItem,
    deleteItem,
    deleteItems,
    createItems
};
