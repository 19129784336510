import { useDispatch } from "react-redux";
import usePaginatedData from "./usePaginatedData";
import { useState } from "react";
import { setSnack } from "../store/actions/snack";
import {mailingListGroupService} from "../api/services/mailingListGroupService";
import {mailingListGroupItemService} from "../api/services/mailingListGroupItemService";

const useMailingListsGroupsAssociations = (
    mailing_lists_group_id,
    initialFilters = {
        mailing_lists: [],
    }
) => {
    const dispatch = useDispatch();

    const [mailingListsGroupsAssociations, setMailingListsGroupsAssociations] = usePaginatedData();

    const [filters, setFilters] = useState(initialFilters);

    const [sort, setSort] = useState({
        sortType: "desc",
        sortBy: "id"
    });

    const [loadingMailingListsGroupsAssociations, setLoadingMailingListsGroupsAssociations] = useState(true);

    const fetchMailingListsGroupsAssociations = (
        {
            itemsPerPage = 5,
            currentPage = mailingListsGroupsAssociations.page,
            sortType = sort.sortType,
            sortBy = sort.sortBy,
            paginate = true,
            mailing_lists = filters.mailing_lists,

        } = {}
    ) => {
        const mailing_list_ids = mailing_lists.map((option) => option.value)
        setLoadingMailingListsGroupsAssociations(true);

        mailingListGroupItemService.fetchAllMailingListsGroupsAssociations(
            itemsPerPage,
            currentPage,
            sortType,
            sortBy,
            paginate,
            mailing_list_ids,
            mailing_lists_group_id
        )
            .then(response => {
                setLoadingMailingListsGroupsAssociations(false);
                setMailingListsGroupsAssociations(response.data);
            })
            .catch(errorResponse => {
                setLoadingMailingListsGroupsAssociations(false);
                dispatch(setSnack(errorResponse.message, "error"));
            });
    };

    return {
        mailingListsGroupsAssociations,
        setMailingListsGroupsAssociations,
        filters,
        setFilters,
        sort,
        setSort,
        loadingMailingListsGroupsAssociations,
        fetchMailingListsGroupsAssociations
    };
};

export default useMailingListsGroupsAssociations;
