import React from 'react';
import CustomSwitch from "./CustomSwitch";
import useModal from "../hooks/useModal";
import ConfirmationModal from "./ConfirmationModal";

const CustomSwitchWithConfirmation = (
    {
        title,
        description,
        item,
        api,
        handleSubmit
    }
) => {
    const confirmationModal = useModal()

    const modal = <ConfirmationModal
        title={title}
        description={description}
        handleClose={confirmationModal.handleModalClose}
        handleSubmit={() => handleSubmit(item, confirmationModal.handleModalClose)}
        open={confirmationModal.modalOpen}
        loading={api.loading}
    />


    return (
        <>
            <CustomSwitch
                sx={{m: 1}}
                checked={item.is_active}
                onChange={confirmationModal.handleModalOpen}
            />
            {modal}
        </>
    );
};

export default CustomSwitchWithConfirmation;