import React from 'react';
import {Typography} from "@mui/material";

const ManualScheduleHelperModalText = ({helperText}) => {
    return (
        <Typography variant={"alerts"}>
            {helperText}
        </Typography>
    );
};

export default ManualScheduleHelperModalText;