import {useState, useEffect, useCallback} from 'react';
import useApi from '../hooks/useApi';
import usePagination from '../hooks/usePagination';
import useSort from '../hooks/useSort';
import useFilters from '../hooks/useFilters';
import {filtersDefaultValues, filtersElementsTypes} from "../utils/constants";
import dayjs from "dayjs";

const useData = (apiCall, initialFilters = {}, initialPagination = {}, initialSort = {}, queryParams = {}) => {
    const [items, setItems] = useState([]);
    const [refreshItems, setRefreshItems] = useState(false);
    const [totals, setTotals] = useState({})

    const fetchItemsApi = useApi(apiCall);

    const itemsPagination = usePagination({
        page: parseInt(queryParams.page) || 1,
        per_page: parseInt(queryParams.per_page) || 10,
        ...initialPagination
    });

    const itemsSort = useSort({
        sortBy: queryParams.sortBy || "updated_at",
        sortOrder: "desc",
        ...initialSort
    });

    const initializedFilters = Object.keys(initialFilters).reduce((acc, key) => {
        const filter = initialFilters[key];
        const queryValue = queryParams[key];
        let defaultValue;

        if (queryValue !== undefined && queryValue !== "") {
            if (filter.type === filtersElementsTypes.SELECT) {
                defaultValue = filter.options?.find(option => option.value == queryValue)
            } else if (filter.type === filtersElementsTypes.DATE || filter.type === filtersElementsTypes.TIME) {
                defaultValue = dayjs.unix(parseInt(queryValue) / 1000)
            } else {
                defaultValue = queryValue;
            }
        } else {
            defaultValue = queryParams[key] !== undefined
                ? filtersDefaultValues[filter.type]
                : filter.defaultValue !== undefined
                    ? filter.defaultValue
                    : filtersDefaultValues[filter.type];
        }
        acc[key] = {
            ...filter,
            defaultValue,
        };
        return acc;
    }, {});

    const itemsFilters = useFilters(initializedFilters);

    const handleRefreshItems = () => setRefreshItems(prev => !prev);

    const fetchItems = useCallback(() => {
        fetchItemsApi.makeRequest(
            {
                ...itemsFilters.values,
                ...itemsPagination.values,
                ...itemsSort.values,
            },
            (response) => {
                setItems(response.data.items);
                setTotals(response.data.totals)
                itemsPagination.setPaginationInfo({
                    total: response.data.total,
                    pages: response.data.pages,
                    page: response.data.page,
                });
            }
        );
    }, [
        fetchItemsApi,
        itemsFilters.values,
        itemsPagination.values,
        itemsSort.values,
    ]);

    useEffect(() => {
        if (itemsPagination.paginationInfo.page !== -1)
            fetchItems();
    }, [
        refreshItems,
        itemsPagination.values,
        itemsSort.values,
    ]);

    useEffect(() => {
        if (itemsPagination.paginationInfo.page !== -1)
            itemsPagination.setValues((prev) => ({...prev, page: 1}));
        else {
            fetchItems();
        }
    }, [itemsFilters.values]);

    return {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    };
};

export default useData;
