import axios from "axios";
import {store} from "../store";
import {setSnack} from "../store/actions/snack";
import {logout, logoutAction} from "../store/actions/auth";

const {REACT_APP_API} = process.env;

const buildClient = (responseType= "json") => {
    const accessToken = localStorage.getItem("token")
    const client = axios.create({
        baseURL: REACT_APP_API,
        headers: {'api-key': accessToken},
        responseType
    });

    client.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if (error.response) {

            if (error.response.status === 401) {
                store.dispatch(logoutAction())
                store.dispatch(setSnack("You are not authorized to perform this action, please log in again!", "error"))
            }
            return Promise.reject(error.response);
        }

        return Promise.reject({data: {message: "Could not access API"}, status: 500})
    });

    return client
}

export default buildClient;