import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import useResource from "../hooks/useResource";
import useModal from "../hooks/useModal";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import AddNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/AddNetworkIntegrationModal";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {offerService} from "../api/services/offerService";
import {networkService} from "../api/services/networkService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import EditNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/EditNetworkIntegrationModal";
import AddOffer from "../components/Offer/AddOffer";
import AddOfferModal from "../components/Offer/OfferModal/AddOfferModal";
import EditOfferModal from "../components/Offer/OfferModal/EditOfferModal";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import OfferStatus from "../components/Offer/OfferStatus";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import useApi from "../hooks/useApi";
import useConfirmationModal from "../hooks/useConfirmationModal";
import useDeleteMultipleItems from "../hooks/useDeleteMultipleItems";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";

const Offers = () => {
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        offerService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            is_active: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Active", value: true},
                    {key: "Inactive", value: false}
                ],
                label: "Status"
            },
            network_integration_id: {
                label: "Network Integrations",
                apiCall: networkIntegrationService.getItems,
                searchField: "name",
                setOptionLabel: (item) => item.name,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            category_id: {
                label: "Category",
                apiCall: categoryService.getItems,
                searchField: "name",
                setOptionLabel: (item) => item.name,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {},
        urlParams.queryParams
    );

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        offerService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        offerService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        offerService.getItems,
        urlParams.queryParams
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    const switchButtonApi = useApi(offerService.updateItemStatus)


    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])


    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'network_integration.name',
            numeric: false,
            disablePadding: false,
            label: 'Network Integration',
            nested: true,
            width: "15%"
        },
        {
            id: 'category.name',
            numeric: false,
            disablePadding: false,
            label: 'Category',
            nested: true,
            width: "15%"
        },
        {
            id: 'is_active',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            width: "7%",
            element: (item) => (<CustomSwitchWithConfirmation
                title={"Status Change"}
                description={"Are you sure you want to change this offer?"}
                item={item}
                api={switchButtonApi}
                handleSubmit={(selectedItem, onSuccess) => {
                    switchButtonApi.makeRequest(
                        {
                            ...selectedItem,
                            is_active: !selectedItem.is_active
                        },
                        () => {
                            handleRefreshItems()
                            onSuccess()
                        }
                    )
                }}
            />)
        },
        {
            id: 'link',
            numeric: false,
            disablePadding: false,
            label: 'Link',
            width: "15%"
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    console.debug(selectedItems)
    return <>
        <Box>
            <CustomAppBar handleModalOpen={handleOpenCreateItemModal} addButtonText={"Create Offer"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditOfferModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddOfferModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Offer"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> offer?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete Offers"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> offer(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
};


export default Offers