import React from 'react';
import {Controller} from "react-hook-form";
import CustomTimePicker from "./CustomTimePicker";

const ControlledTimePickerField = ({control, name, label, inputProps = null, disabled = false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (

                <CustomTimePicker
                    value={value}
                    onChange={onChange}
                    label={label}
                />
            )}
        />
    );
};

export default ControlledTimePickerField;