import React from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

const CustomModalHeader = ({title, handleClose}) => {
    return (
        <Grid container alignItems={"center"} columns={12} item xs={12}>
            <Grid item xs={11} >
                <Typography variant={"title"}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={1} align={"right"}>
                <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                    <Close/>
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default CustomModalHeader;