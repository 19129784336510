import React, {useEffect, useState} from 'react';
import {Box, Grid} from "@mui/material";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import CustomDateTimePicker from "../CustomDateTimePicker";
import ManualScheduleHelperModalText from "./ManualScheduleHelperModalText";
import ManualScheduleHelperModalContent from "./ManualScheduleHelperModalContent";
import CustomAPIAutocompleteSelectField from "../CustomAPIAutocompleteSelectField";
import {contentService} from "../../api/services/contentService";
import {offerService} from "../../api/services/offerService";
import CustomTextField from "../CustomTextField";
import ControlledTextField from "../ControlledTextField";
import ControlledAutocompleteSelectField from "../ControlledAutocompleteSelectField";
import ControlledDatePickerField from "../ControlledDatePickerField";
import ControlledDatePicker from "../ControlledDatePicker";
import ControlledTimePicker from "../ControlledTimePicker";

const ManualScheduleHelperModalSettings = (
    {
        selectedMailingLists,
        control,
        setValue = null,
        description,
        defaultConfigsName = null
    }) => {

    const [configsOptions, setConfigsOptions] = useState([]);

    useEffect(() => {
        let configs = null;
        Object.entries(selectedMailingLists).forEach(([listId, list], index) => {
            const listConfigs = new Set(list.configs.map(config => config.name));
            if (index === 0) {
                configs = listConfigs; // Initialize with the first list's configs
            } else {
                configs = new Set([...configs].filter(config => listConfigs.has(config)));
            }
        });

        const options = Array.from(configs).map((configName, index) => {
            return {
                key: configName,
                value: index
            };
        });

        setConfigsOptions(options);
        if (options.length > 0 && setValue) {
            const selectedOption = options.filter(option => option.key === defaultConfigsName)
            if (selectedOption.length > 0)
                setValue("config", selectedOption[0]); // Update the form value
        }
    }, [selectedMailingLists, setValue]);


    return (
        <ManualScheduleHelperModalContent>
            <Grid item xs={12}>
                <ManualScheduleHelperModalText helperText={"Pick Campaigns Send Settings"}/>
            </Grid>
            <Grid item xs={12}>
                {description}
            </Grid>
            <Grid item xs={12}>
                <Box sx={{height: 380 + 64, mb: 2}}>
                    <Grid container spacing={2}>


                        <Grid item xs={12}>
                            <ControlledTextField
                                control={control}
                                name={"name"}
                                label={"Name"}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <ControlledAutocompleteSelectField
                                control={control}
                                name={"config"}
                                label={"Config"}
                                options={configsOptions}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ControlledTimePicker
                                control={control}
                                name={"send_time"}
                                label={"Send Time"}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <ControlledTextField
                                control={control}
                                name={"min_sends"}
                                label={"Min Sends"}
                            />
                        </Grid>
                        <Grid item xs={6}>

                            <ControlledTextField
                                control={control}
                                name={"content_lookback_days"}
                                label={"Excluded Contents LookBack Days"}
                            />
                        </Grid>
                        <Grid item xs={6}>

                            <ControlledTextField
                                control={control}
                                name={"excluded_offers_lookback_days"}
                                label={"Excluded Offers LookBack Days"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledDatePicker
                                control={control}
                                name={"start_datetime"}
                                label={"Stats Date Start"}
                                disableFuture={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledDatePicker
                                control={control}
                                name={"end_datetime"}
                                label={"Stats Date End"}
                                disableFuture={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>


        </ManualScheduleHelperModalContent>
    );
};

export default ManualScheduleHelperModalSettings;