import React, {useEffect, useState} from 'react';
import {Box, IconButton, useTheme} from "@mui/material";
import useResource from "../hooks/useResource";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import {offerService} from "../api/services/offerService";
import {partnerService} from "../api/services/partnerService";
import {mailingListService} from "../api/services/mailingListService";
import {verticalService} from "../api/services/verticalService";
import {contentReportingService} from "../api/services/contentReportingService";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {campaignReportingService} from "../api/services/campaignReportingService";
import {brandService} from "../api/services/brandService";
import {espIntegrationService} from "../api/services/espIntegrationService";
import CampaignReportingDataAlertChip from "../components/CampaignReporting/CampaignReportingDataAlertChip";
import ExternalLinkButton from "../components/Campaign/ExternalLinkButton";
import dayjs from "dayjs";

const ContentReporting = () => {
    const theme = useTheme()

    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {

        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        contentReportingService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Send Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')

            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Send Date End",
                columns: 6,
                disableFuture: true
            },
            subject_line: {
                type: filtersElementsTypes.TEXT,
                label: "Subject Line",
            },
            from_name: {
                type: filtersElementsTypes.TEXT,
                label: "From Name",
            },
            offer_id: {
                label: "Offers",
                apiCall: offerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            mailing_list_id: {
                label: "Lists",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            vertical_id: {
                label: "Verticals",
                apiCall: verticalService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            category_id: {
                label: "Categories",
                apiCall: categoryService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            network_integration_id: {
                label: "Network Integrations",
                apiCall: networkIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            min_sends: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Sends",
                columns: 6
            },
            min_clicks: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Clicks",
                columns: 6
            },
            send_time_start: {
                type: filtersElementsTypes.TIME,
                label: "Send Time Start",
                columns: 6
            },
            send_time_end: {
                type: filtersElementsTypes.TIME,
                label: "Send Time End",
                columns: 6
            }
        },
        {},
        {
            sortBy: "offer_name",
            sortOrder: "asc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'content_id',
            numeric: false,
            disablePadding: true,
            width: "48px",
            element: (item) => (
                <IconButton aria-label="preview" onClick={() => handleModalOpen(item.content_id)}>
                    <Visibility/>
                </IconButton>
            )
        },
        {
            id: 'offer_name',
            numeric: false,
            disablePadding: false,
            label: 'Offer',
            width: "300px",
            highlight: true
        },
        {
            id: 'content_name',
            numeric: false,
            disablePadding: true,
            label: 'Content',
            width: "80px"
        },
        {
            id: 'network_name',
            numeric: false,
            align: "right",
            disablePadding: false,
            label: 'Network',
            width: "200px"
        },
        {
            id: 'sends',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Sends',
            width: "150px",
            totals: totals.total_sends

        },
        {
            id: 'epc',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'EPC',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_epc
        },
        {
            id: 'ppm',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'PPM',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_ppm
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "170px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "100px",
            totals: totals.total_clicks
        },
        {
            id: 'unique_open_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Unique Open Rate',
            width: "200px",
            float: true,
            trailingSign: "%",
            totals: totals.total_unique_open_rate
        },
        {
            id: 'unique_click_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Unique Click Rate',
            width: "200px",
            float: true,
            trailingSign: "%",
            totals: totals.total_unique_click_rate
        },
        {
            id: 'from_name',
            numeric: false,
            disablePadding: false,
            label: 'From Name',
            width: "200px"
        },
        {
            id: 'subject_line',
            numeric: false,
            disablePadding: false,
            label: 'Subject Line',
            width: "250px"
        },
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <ContentReportingPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
    </>
}

export default ContentReporting