import React from 'react';
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import {routes} from "../../utils/routes";
import {useNavigate} from "react-router-dom";
import SubmitButton from "../SubmitButton";
import useModal from "../../hooks/useModal";
import ConfirmationModal from "../ConfirmationModal";

const DeleteAutomaticallyCreatedCampaignsButton = ({removeAutomaticallyCreatedCampaignsAPI: {removeAutomaticallyCreatedCampaignsAPI, handleRefreshCampaigns}}) => {
    const dispatch = useDispatch()
    // const navigate = useNavigate()

    const {
        handleModalClose,
        handleModalOpen,
        modalOpen
    } = useModal()

    const handleSubmit = () => {

        removeAutomaticallyCreatedCampaignsAPI.makeRequest(
            null,
            (response) => {
                dispatch(setSnack(response.message, "success"))
                // navigate(routes.campaigns + "?creation_method=2")
                handleModalClose()
                handleRefreshCampaigns()
            }
        )
    }


    return (
        <>
            <Button color={"error"} onClick={() => handleModalOpen()} sx={{}} variant={"contained"}>
                Delete Campaigns
            </Button>
            {modalOpen &&
                <ConfirmationModal
                    title={"Delete Campaigns"}
                    description={`Are you sure you want to delete automatically created campaigns?`}
                    handleClose={handleModalClose}
                    handleSubmit={handleSubmit}
                    loading={removeAutomaticallyCreatedCampaignsAPI.loading}
                    open={true}
                />
            }
        </>
    );
};

export default DeleteAutomaticallyCreatedCampaignsButton;