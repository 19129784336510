import React, {useEffect} from 'react';
import {AppBar as MuiAppBar, Box, Button, Grid, IconButton, styled, Toolbar, Typography} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {useNavigate, Link, useLocation} from "react-router-dom";
import {routes} from "../../utils/routes";
import {logoutAction} from "../../store/actions/auth";
import {connect} from "react-redux";
import {drawerWidth} from "../../utils/drawerSettings";
import {navItems} from "../../navigation/navItems";
import {extractNameFromPath} from "../../utils/formatText";
import SettingsButton from "../NavigationBar/SettingsButton"
import AddItemButton from "../AddItemButton";
import MultipleSelectButton from "../MultipleSelectButton";
import AutomatedSchedulingButton from "../AutomatedSchedulingConfig/AutomatedSchedulingButton";
import DeleteAutomaticallyCreatedCampaignsButton
    from "../AutomatedSchedulingConfig/RemoveAutomaticallyCreatedCampaignsButton";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const CustomAppBar = (
    {
        handleModalOpen,
        addButtonText,
        selectedItems,
        onDeleteMultipleItems,
        onRetrySelectedItems,
        appBarTitle = null,
        automatedSchedulingAPI = null,
        removeAutomaticallyCreatedCampaignsAPI = null
    }
) => {
    const location = useLocation();

    let activeItem = navItems.find(item => item.path === location.pathname);
    if (!activeItem) {
        const nestedItem = navItems.flatMap(item => item.collapsible || [])
            .find(subItem => subItem.path === location.pathname);
        if (nestedItem) {
            activeItem = nestedItem; // Assign the whole item found
        } else {
            // If no nested item is found either, create a fallback item
            activeItem = {
                label: extractNameFromPath(location.pathname)
            };
        }
    }

    useEffect(() => {
        document.title = activeItem.label;
    }, [activeItem]);

    const title = appBarTitle || activeItem.label
    console.debug(title)
    return (
        <Grid container alignItems="center" spacing={2}>
            {/* Title aligned to the left */}
            <Grid item xs={6}>
                <Typography variant="title"
                            sx={{
                                display: "block",
                                width: "100%",
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid container item xs={6} spacing={2}
                  justifyContent="flex-end">
                <Grid item>
                    <MultipleSelectButton
                        selectedItems={selectedItems}
                        onDeleteMultipleItems={onDeleteMultipleItems}
                        onRetrySelectedItems={onRetrySelectedItems}
                    />
                </Grid>


                {!!removeAutomaticallyCreatedCampaignsAPI &&
                    <Grid item>
                        <DeleteAutomaticallyCreatedCampaignsButton removeAutomaticallyCreatedCampaignsAPI={removeAutomaticallyCreatedCampaignsAPI}/>
                    </Grid>
                }

                {!!automatedSchedulingAPI &&
                    <Grid item>
                        <AutomatedSchedulingButton automatedSchedulingAPI={automatedSchedulingAPI}/>
                    </Grid>
                }

                {/* Action Button */}
                {!!handleModalOpen &&
                    <Grid item>
                        <AddItemButton handleModalOpen={handleModalOpen} text={addButtonText}/>
                    </Grid>
                }

                {/* Settings Button aligned to the right */}
                <Grid item>
                    <SettingsButton/>
                </Grid>
            </Grid>
            {/* Place holder for spacing */}
            {/*<Grid item xs={2}></Grid>*/}


        </Grid>
    );
};
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logoutAction()),
    };
};
export default connect(null, mapDispatchToProps)(CustomAppBar);