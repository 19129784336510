import React, {useState} from 'react';
import CustomModalHeader from "../CustomModal/CustomModalHeader";
import CustomModalFooter from "../CustomModal/CustomModalFooter";
import CustomModalBody from "../CustomModal/CustomModalBody";
import CustomModal from "../CustomModal/CustomModal";
import useManualScheduleStepper from "../../hooks/useManualScheduleStepper";
import ManualScheduleSelectMailingListGroups from "./ManualScheduleSelectMailingListGroups";
import ManualScheduleSelectMailingLists from "./ManualScheduleSelectMailingLists";
import ManualScheduleHelperModalDescription from "./ManualScheduleHelperModalDescription";
import ManualScheduleHelperModalSettings from "./ManualScheduleHelperModalSettings";

const ManualScheduleHelperModal = (
    {
        handleClose,
        open,
        contents,
        handleSchedulingHelperCompleted
    }
) => {

    const [selectedMailingLists, setSelectedMailingLists] = useState({})
    const [selectedMailingListGroups, setSelectedMailingListGroups] = useState({})
    const [selectedSettings, setSelectedSettings] = useState({
        config: null,
        content: null,
        sendTime: null
    })


    const handleComplete = () => {
        console.debug("completed")
        handleClose()
        handleSchedulingHelperCompleted(selectedMailingLists, selectedSettings)
    }

    const stepper = useManualScheduleStepper(
        {
            handleClose,
            selectedMailingLists,
            selectedMailingListGroups,
            handleComplete
        })

    let mainContainer;

    const handleSelectMailingLists = (newSelectedMailingLists, newState) => {
        const updatedItems = {...selectedMailingLists}
        if (newState) {
            newSelectedMailingLists.forEach(item => {
                updatedItems[item.id] = item
            })
        } else {
            newSelectedMailingLists.forEach(item => {
                if (updatedItems.hasOwnProperty(item.id)) {
                    delete updatedItems[item.id]
                }
            })
        }
        setSelectedMailingLists(updatedItems)
    }

    const handleSelectMailingListGroups = (newSelectedMailingListGroups, newState) => {
        const updatedSelectedMailingListGroups = {...selectedMailingListGroups};
        const newSelectedMailingLists = []

        if (newState) {
            // If newState is true, add all items to the dictionary
            newSelectedMailingListGroups.forEach(item => {
                updatedSelectedMailingListGroups[item.id] = item;
                newSelectedMailingLists.push(...updatedSelectedMailingListGroups[item.id].mailing_lists)
            });

        } else {
            newSelectedMailingListGroups.forEach(item => {
                if (updatedSelectedMailingListGroups.hasOwnProperty(item.id)) {
                    newSelectedMailingLists.push(...updatedSelectedMailingListGroups[item.id].mailing_lists)
                    delete updatedSelectedMailingListGroups[item.id]
                }
            });
        }
        handleSelectMailingLists(newSelectedMailingLists, newState)
        setSelectedMailingListGroups(updatedSelectedMailingListGroups);
    }

    const handleChangeSelectedSettings = (newSetting) => {
        const updatedSelectedSettings = {...selectedSettings, ...newSetting}
        setSelectedSettings(updatedSelectedSettings)
    }

    const description = <ManualScheduleHelperModalDescription noOfItems={Object.keys(selectedMailingLists).length}/>


    if (stepper.activeStep === 0) {
        mainContainer = <ManualScheduleSelectMailingListGroups
            handleSelectMailingListGroups={handleSelectMailingListGroups}
            selectedMailingListGroups={selectedMailingListGroups}
            description={description}
        />
    } else if (stepper.activeStep === 1) {
        mainContainer = <ManualScheduleSelectMailingLists
            handleSelectMailingLists={handleSelectMailingLists}
            selectedMailingLists={selectedMailingLists}
            description={description}
        />
    } else {
        mainContainer = <ManualScheduleHelperModalSettings
            handleChangeSelectedSettings={handleChangeSelectedSettings}
            selectedSettings={selectedSettings}
            contents={contents}
            selectedMailingLists={selectedMailingLists}
            description={description}
        />
    }

    return (
        <CustomModal
            open={open}
            handleClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            containerProps={{
                maxWidth: "sm"
            }}
        >
            <CustomModalHeader handleClose={handleClose} title={"Scheduling Helper"}/>
            <CustomModalBody>
                {stepper.stepperHTML}
                {mainContainer}
            </CustomModalBody>
            <CustomModalFooter justifyContent={"flex-end"}>
                {stepper.backButtonHTML}
                {stepper.nextButtonHTML}
            </CustomModalFooter>
        </CustomModal>
    );
};

export default ManualScheduleHelperModal;