import React from 'react';
import {Box, Button, Grid, Popover, styled} from "@mui/material";
import CustomTextField from "../CustomTextField";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import {filtersElementsTypes} from "../../utils/constants";
import ControlledTextField from "../ControlledTextField";
import ControlledAutocompleteSelectField from "../ControlledAutocompleteSelectField";
import ControlledDatePickerField from "../ControlledDatePickerField";
import ControlledDateTimePickerField from "../ControlledDateTimePicker";
import ControlledTimePickerField from "../ControlledTimePicker";
import ControlledDatePicker from "../ControlledDatePicker";
import ControlledAPIAutocompleteSelectField from "../ControlledAPIAutocompleteSelectField";

const PopoverButton = styled(Button)(() => ({
    width: 92
}))

const CustomPopover = ({id, open, anchorEl, handleClose, filtersData, control, onSubmit, primaryFilter}) => {
    // console.debug(filtersData)
    const filterFields = Object.keys(filtersData).map((filterName) => {
        let columns = filtersData[filterName].columns || 12
        if (filterName !== primaryFilter) {
            if (filtersData[filterName].type === filtersElementsTypes.TEXT) {
                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledTextField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                        />
                    </Grid>
                )
            } else if (filtersData[filterName].type === filtersElementsTypes.NUMBER) {
                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledTextField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                            type={"number"}
                        />
                    </Grid>
                )
            } else if (filtersData[filterName].type === filtersElementsTypes.MULTISELECT) {
                const listsOptions = filtersData[filterName].options.map((item) => {
                    return {
                        key: filtersData[filterName].optionField ? item[filtersData[filterName].optionField] : item.name,
                        value: item.id
                    }
                })

                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledAutocompleteSelectField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                            options={listsOptions}
                            multiple={true}
                        />
                    </Grid>
                )
            } else if (filtersData[filterName].type === filtersElementsTypes.MULTISELECT_API) {

                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                            multiple={true}
                            apiCall={filtersData[filterName].apiCall}
                            apiFilters={filtersData[filterName].apiFilters}
                            setOptionLabel={filtersData[filterName].setOptionLabel}
                            searchField={filtersData[filterName].searchField}
                            sortBy={filtersData[filterName].sortBy}
                            sortOrder={filtersData[filterName].sortOrder}
                        />
                    </Grid>
                )
            } else if (filtersData[filterName].type === filtersElementsTypes.SELECT) {

                // const listsOptions = filtersData[filterName].options.map((network) => {
                //     return {
                //         key: network.key,
                //         value: network.value
                //     }
                // })
                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledAutocompleteSelectField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                            options={filtersData[filterName].options}/>

                    </Grid>
                )
            } else if (filtersData[filterName].type === filtersElementsTypes.DATETIME) {

                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledDateTimePickerField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                        />

                    </Grid>
                )
            }else if (filtersData[filterName].type === filtersElementsTypes.TIME) {

                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledTimePickerField
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                        />

                    </Grid>
                )
            }else if (filtersData[filterName].type === filtersElementsTypes.DATE) {

                return (
                    <Grid item xs={columns} key={filterName}>
                        <ControlledDatePicker
                            control={control}
                            name={filterName}
                            label={filtersData[filterName].label}
                            disableFuture={!!filtersData[filterName].disableFuture}
                        />

                    </Grid>
                )
            }
        }
    })

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}

            sx={{
                mt: 1,
            }}
        >
            <form onSubmit={onSubmit}>

                <Box sx={{width: 640, p: 2}}>
                    <Grid container spacing={2}>
                        {filterFields}
                        <Grid item xs={12} align={"right"}>
                            <PopoverButton variant={"outlined"} sx={{mr: 2}} onClick={handleClose}>
                                Cancel
                            </PopoverButton>
                            <PopoverButton variant={"contained"} type={"submit"}>
                                Search
                            </PopoverButton>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Popover>

    );
};

export default CustomPopover;