import React from 'react';
import {Controller} from "react-hook-form";
import {LocalizationProvider, renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import CustomDateTimePicker from "./CustomDateTimePicker";

const ControlledDateTimePickerField = ({control, name, label, inputProps = null, disabled = false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (

                <CustomDateTimePicker
                    value={value}
                    onChange={onChange}
                    label={label}
                />
            )}
        />
    );
};

export default ControlledDateTimePickerField;