export const MAROPOST_ID = 1

export const filtersElementsTypes = {
    TEXT: "text",
    MULTISELECT: "multiselect",
    SELECT: "select",
    DATETIME: "datetime",
    DATE: "date",
    TIME: "time",
    NUMBER: "number",
    MULTISELECT_API: "multiselect-api"
}

export const filtersDefaultValues = {
    [filtersElementsTypes.TEXT]: "",
    [filtersElementsTypes.NUMBER]: "",
    [filtersElementsTypes.MULTISELECT]: [],
    [filtersElementsTypes.MULTISELECT_API]: [],
    [filtersElementsTypes.SELECT]: null,
    [filtersElementsTypes.DATETIME]: "",
    [filtersElementsTypes.TIME]: "",
    [filtersElementsTypes.DATE]: ""
}

export const EVERFLOW = 1
export const HASOFFERS = 3
export const BUYGOODS = 4
export const NATIVEPATH = 5
export const CLICKBANK = 6
export const MAXWEB = 7
export const ULTRACART = 8
