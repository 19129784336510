import React from 'react';
import {Box, LinearProgress, useTheme} from "@mui/material";

const EnhancedTableLoader = ({totals=false}) => {
    const theme = useTheme()
    return (
        <LinearProgress
            style={{
                position: 'absolute',
                width: '100%',
                top: theme.customTokens.defaultRowHeight * (1 + totals), // Adjust this based on your table header's height
                zIndex: 1
            }}
        />
    );
};

export default EnhancedTableLoader;