import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";

import {Outlet} from "react-router-dom";
import {connect} from "react-redux";
import {routes} from "../utils/routes";
import Login from "../pages/Login";

const AuthNavigation = ({isAuthenticated}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            navigate(routes.homePage)
        }
    }, [isAuthenticated, navigate])

    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route path={"login"} element={<Login/>}/>
            </Route>
        </Routes>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
    };
};

export default connect(mapStateToProps)(AuthNavigation);