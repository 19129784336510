import React, {useState} from 'react';
import {Grid} from "@mui/material";
import ControlledTextField from "../../ControlledTextField";


const MaropostIntegration = ({control}) => {

    return (
        <Grid item xs={12}>
            <ControlledTextField control={control} name={"maropost_external_account_id"}
                                 label={"Maropost Account ID"}/>
        </Grid>

    );
};

export default MaropostIntegration