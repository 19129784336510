import React, {useState} from 'react';
import {TableBody, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import CustomDateTimePicker from "../CustomDateTimePicker";
import CustomAPIAutocompleteSelectField from "../CustomAPIAutocompleteSelectField";
import {contentService} from "../../api/services/contentService";
import ManualScheduleTableRow from "./ManualScheduleTableRow";




const ManualScheduleTableBody = (
    {
        items,
        getMailingListName,
        getMailingListConfigs,
        handleChangeSettings,
        errors
    }
) => {
    const theme = useTheme()
    let content

    if (items.length) {
        content = (

            <>
                {items.map((item, itemIndex) => {
                    // const isItemSelected = table.isSelected(item);
                    return <ManualScheduleTableRow key={itemIndex} item={item} itemIndex={itemIndex} handleChangeSettings={handleChangeSettings}/>
                })}

                {items.length < 10 && (
                    <TableRow
                        style={{
                            height: theme.customTokens.defaultRowHeight * (10 - items.length),
                        }}
                    >
                        <TableCell colSpan={4}/>
                    </TableRow>
                )}
            </>
        )
    } else {
        content = (
            <TableRow
                // hover
                disabled={true}
                // role="checkbox"
                // tabIndex={-1}
                style={{height: 10 * theme.customTokens.defaultRowHeight}}
            >
                <TableCell colSpan={4} align={"center"}>
                    <Typography variant={"table"}>
                        Access Scheduling Helper from the upper right corner to start scheduling.
                    </Typography>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <TableBody>
            {content}
        </TableBody>
    )
};

export default ManualScheduleTableBody;