import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common
        }

        if (!!args.is_active) {
            data.is_active = args.is_active.value
        }

        if (args.brand_id) {
            data.brand_id = args.brand_id.map(option => option.value)
        }

        if (args.vertical_id) {
            data.vertical_id = args.vertical_id.map(option => option.value)
        }

        if (args.partner_id) {
            data.partner_id = args.partner_id.map(option => option.value)
        }

        if (args.mailing_list_id) {
            data.mailing_list_id = args.mailing_list_id.map(option => option.value)
        }

        apiClient.get(endpoints.mailingList, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const createItem = (name, campaign_tag, partner_id, brand_id, vertical_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            campaign_tag,
            partner_id,
            brand_id,
            vertical_id
        };

        apiClient.post(endpoints.mailingList, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateItem = (mailing_list_id, name, campaign_tag, partner_id, brand_id, vertical_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            campaign_tag,
            partner_id,
            brand_id,
            vertical_id
        };

        apiClient.patch(endpoints.mailingList + "/" + mailing_list_id, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateStatus = ({id, is_active}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            is_active,
        }

        apiClient.patch(`${endpoints.mailingList}/${id}`, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(endpoints.mailingList + "/" + itemId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const fetchJoinedMailingLists = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        apiClient.get(endpoints.joinedMailingList, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.mailingList + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const fetchAvailableItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            excluded_mailing_list_group_id: args.excluded_mailing_list_group_id,
            mailing_list_name: args.mailing_list_name,
        }

        apiClient.get(endpoints.mailingList+"/available-mailing-lists", {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

export const mailingListService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    updateStatus,
    fetchJoinedMailingLists,
    deleteItems,
    fetchAvailableItems
};
