import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, Typography, useTheme} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {categoryService} from "../api/services/categoryService";
import {mailingListService} from "../api/services/mailingListService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useDeleteData from "../hooks/useDeleteData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import EditCategoryModal from "../components/Category/CategoryModal/EditCategoryModal";
import AddCategoryModal from "../components/Category/CategoryModal/AddCategoryModal";
import ConfirmationModal from "../components/ConfirmationModal";
import {automatedSchedulingConfigService} from "../api/services/automatedSchedulingConfigsService";
import AddAutomatedSchedulingConfigModal
    from "../components/AutomatedSchedulingConfig/CreateAutomatedSchedulingConfigModal";
import EditAutomatedSchedulingConfigModal
    from "../components/AutomatedSchedulingConfig/EditAutomatedSchedulingConfigModal";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import useApi from "../hooks/useApi";
import {contentService} from "../api/services/contentService";
import {automatedSchedulingService} from "../api/services/automatedSchedulingService";
import AutomatedCampaignsPreview from "../components/AutomatedSchedulingConfig/AutomatedCampaignsPreview";
import {campaignService} from "../api/services/campaignService";

const AutomatedSchedulingConfig = () => {
    const theme = useTheme()
    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        automatedSchedulingConfigService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            config_name: {
                type: filtersElementsTypes.TEXT,
                label: "Config Name",
            },
            mailing_list_id: {
                label: "Lists",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            }
        },
        {},
        {},
        urlParams.queryParams
    )

    const {
        items: campaigns,
        handleRefreshItems: handleRefreshCampaigns,
        fetchItemsApi: fetchCampaignsApi,
    } = useData(
        campaignService.getItems,
        {
            creation_method: {
                defaultValue: {key: "Manual", value: 2},
            },
            status: {
                defaultValue: {key: "Manual", value: -2},
            },
        },
        {
            paginate: false
        },
        {}
    )

    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        automatedSchedulingConfigService.deleteItem,
        handleRefreshItems,
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        automatedSchedulingConfigService.getItems,
        urlParams.queryParams,
        "category_id"
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    const switchButtonApi = useApi(
        automatedSchedulingConfigService.updateItemStatus
    )

    const automatedSchedulingAPI = useApi(
        automatedSchedulingService.automaticSchedule
    )

    const removeAutomaticallyCreatedCampaignsAPI = useApi(
        automatedSchedulingService.removeAutomaticallySelectedCampaigns
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            highlight: true
        },
        {
            id: 'mailing_lists',
            numeric: true,
            disablePadding: true,
            label: 'Lists No.',
            disableSort: true,
            width: "120px",
            element: (item) => (
                <Typography
                    variant={"table"}
                    sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {Object.keys(item.mailing_lists).length}
                </Typography>)
        },
        {
            id: 'mailing_lists',
            numeric: true,
            disablePadding: true,
            disableSort: true,
            label: 'Inactive Lists No.',
            width: "150px",
            element: (item) => (
                <Typography
                    variant={"table"}
                    sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {Object.values(item.mailing_lists).filter(
            mailingList => !mailingList.is_active
        ).length}
                </Typography>)
        },
        {id: 'config_name', numeric: false, disablePadding: false, label: 'Config', width: "150px"},
        {
            id: 'send_time',
            // numeric: true,
            disablePadding: false,
            label: 'Send At',
            time: true,
            width: "160px"
        },
        {
            id: 'is_active',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            width: "80px",
            element: (item) => (<CustomSwitchWithConfirmation
                title={"Status Change"}
                description={"Are you sure you want to change this content?"}
                item={item}
                api={switchButtonApi}
                handleSubmit={(selectedItem, onSuccess) => {
                    switchButtonApi.makeRequest(
                        {
                            is_active: !selectedItem.is_active,
                            id: selectedItem.id
                        },
                        () => {
                            handleRefreshItems()
                            onSuccess()
                        }
                    )
                }}
            />)
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar
                automatedSchedulingAPI={{automatedSchedulingAPI, handleRefreshCampaigns}}
                removeAutomaticallyCreatedCampaignsAPI={{removeAutomaticallyCreatedCampaignsAPI, handleRefreshCampaigns}}
                handleModalOpen={handleOpenCreateItemModal}
                addButtonText={"Create Config"}
            />

            <Grid container justifyContent="space-between" alignItems="center" >
                <Grid>
                    <Filters filters={itemsFilters}/>
                </Grid>
                <Grid>
                    <AutomatedCampaignsPreview itemsNo={campaigns.length}/>
                </Grid>
            </Grid>

            <EnhancedTable
                table={table}
                headCells={headCells}
                disableSelecting={true}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditAutomatedSchedulingConfigModal
                open={true}
                automatedSchedulingConfig={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddAutomatedSchedulingConfigModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Automation Scheduling Config"}
                description={`Are you sure you want to delete this ${deleteItem.name} Automation Scheduling Config?`}
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
    </>
}

export default AutomatedSchedulingConfig