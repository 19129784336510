import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import useResource from "../hooks/useResource";
import useModal from "../hooks/useModal";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import AddNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/AddNetworkIntegrationModal";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {contentService} from "../api/services/contentService";
import {networkService} from "../api/services/networkService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import EditNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/EditNetworkIntegrationModal";
import AddContent from "../components/Content/AddContent";
import AddContentModal from "../components/Content/ContentModal/AddContentModal";
import EditContentModal from "../components/Content/ContentModal/EditContentModal";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import useApi from "../hooks/useApi";
import useConfirmationModal from "../hooks/useConfirmationModal";
import {offerService} from "../api/services/offerService";
import useDeleteMultipleItems from "../hooks/useDeleteMultipleItems";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import EditOfferModal from "../components/Offer/OfferModal/EditOfferModal";
import AddOfferModal from "../components/Offer/OfferModal/AddOfferModal";

const Contents = () => {
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        contentService.getItems,
        {

            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            subject_line: {
                type: filtersElementsTypes.TEXT,
                label: "Subject Line"
            },
            from_name: {
                type: filtersElementsTypes.TEXT,
                label: "From Name"
            },
            preheader: {
                type: filtersElementsTypes.TEXT,
                label: "Preheader"
            },
            is_active: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Active", value: true},
                    {key: "Inactive", value: false}
                ],
                label: "Status"
            },
            offer_id: {
                label: "Offers",
                apiCall: offerService.getItems,
                sortBy: "name",
                sortOrder: "asc",
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {},
        urlParams.queryParams
    )

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        contentService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        contentService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        contentService.getItems,
        urlParams.queryParams
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    const switchButtonApi = useApi(
        contentService.updateItemStatus
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: "70px"
        },
        {
            id: 'offer.name',
            numeric: false,
            disablePadding: false,
            label: 'Offer',
            nested: true,
            width: "20%"
        },
        {
            id: 'subject_line',
            numeric: false,
            disablePadding: false,
            label: 'Subject Line',
            // width: "15%"
        },
        {
            id: 'from_name',
            numeric: false,
            disablePadding: false,
            label: 'From Name',
            width: "10%"
        },
        {
            id: 'preheader',
            numeric: false,
            disablePadding: false,
            label: 'Preheader',
            width: "10%"
        },
        {
            id: 'is_active',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            align: "right",
            width: "90px",
            element: (item) => (<CustomSwitchWithConfirmation
                title={"Status Change"}
                description={"Are you sure you want to change this content?"}
                item={item}
                api={switchButtonApi}
                handleSubmit={(selectedItem, onSuccess) => {
                    switchButtonApi.makeRequest(
                        {
                            ...selectedItem,
                            is_active: !selectedItem.is_active
                        },
                        () => {
                            handleRefreshItems()
                            onSuccess()
                        }
                    )
                }}
            />)
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)
    console.debug(deleteItem)
    return <>
        <Box>
            <CustomAppBar handleModalOpen={handleOpenCreateItemModal} addButtonText={"Create Content"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditContentModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddContentModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Content"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> content for <strong>{deleteItem.offer.name}</strong> offer?
                    </>
                }                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete Contents"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> content(s)?
                    </>
                }                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
}


export default Contents