import React from 'react';
import CustomTable from "../Table/CustomTable";
import ManualScheduleTableHeader from "./ManualScheduleTableHeader";
import ManualScheduleTableBody from "./ManualScheduleTableBody";
import ManualScheduleTableFooter from "./ManualScheduleTableFooter";

const ManualScheduleTable = (
    {
        campaigns,
        handleChangeSettings,
        getMailingListName,
        getMailingListConfigs,
        handleSubmit,
        loadingCampaigns,
        errors
    }
    ) => {

    return (
        <CustomTable
            sx={{pt:2}}
            rowsPerPage={[10]}
            header={<ManualScheduleTableHeader/>}
            body={<ManualScheduleTableBody
                errors={errors}
                items={campaigns}
                getMailingListConfigs={getMailingListConfigs}
                getMailingListName={getMailingListName}
                handleChangeSettings={handleChangeSettings}
            />}
            footer={<ManualScheduleTableFooter handleSubmit={handleSubmit}  itemsNo={campaigns.length} loadingCampaigns={loadingCampaigns}/>}
        />
    );
};

export default ManualScheduleTable;