import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";

const createItem = (name) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name
        }

        apiClient.post(endpoints.category, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (name, category_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name
        }

        apiClient.patch(endpoints.category + "/" + category_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.category + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.category + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        if (args.category_id) {
            data.category_id = args.category_id.map(option => option.value)
        }

        apiClient.get(endpoints.category, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const categoryService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems
}