import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';


const getItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common
        }

        if (args.esp_id) {
            data.esp_id = args.esp_id.map(option => option.value);
        }

        if (args.esp_integration_id) {
            data.esp_integration_id = args.esp_integration_id.map(option => option.value);
        }

        apiClient.get(endpoints.espIntegration, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then((response) => {
            resolve(response.data)
        }).catch((errorResponse) => {
            reject(errorResponse.data)
        })

    })
}

const createItem = (form_data) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            api_key: form_data.api_key,
            name: form_data.name,
            esp_id: form_data.esp_id,
            maropost_external_account_id: form_data.maropost_external_account_id,
            default_account_address: form_data.default_account_address
        }

        apiClient.post(endpoints.espIntegration, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (data, esp_integration_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.patch(endpoints.espIntegration + "/" + esp_integration_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.espIntegration + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.espIntegration + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const espIntegrationService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems
}