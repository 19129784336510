import React from 'react';
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";


const CustomDatePicker = ({value, label, onChange, sx = {}, ...props}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                slotProps={{ textField: { variant: "standard", fullWidth: true , error: false} }}

                sx={sx}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;