import {Controller} from "react-hook-form";
import {MenuItem, TextField} from "@mui/material";
import React from "react";
import CustomAutocompleteSelectField from "./CustomAutocompleteSelectField";

const ControlledAutocompleteSelectField = ({control, name, label, options, multiple=false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => {
                console.debug(value)
                return <CustomAutocompleteSelectField
                    label={label}
                    options={options}
                    onChange={onChange}
                    value={value}
                    error={error}
                    multiple={multiple}
                />
            }}
        />
    );
}

export default ControlledAutocompleteSelectField