import React from 'react';
import {InputBase} from "@mui/material";
import {Controller} from "react-hook-form";

const PrimaryFilterTextField = ({control, name}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <InputBase
                    sx={{flex: 1}}
                    placeholder="Search Throughout Table"
                    inputProps={{'aria-label': 'search google maps'}}
                    onChange={onChange}
                    value={value}
                />
            )}
        />
    );
};


export default PrimaryFilterTextField;