import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            offer_name: args.offer_name,
        }

        if (args.min_clicks !== "")
            data.min_clicks = args.min_clicks
        if (args.send_time_start !== "")
            data.send_time_start = args.send_time_start.format('HH:mm')
        if (args.send_time_end !== "")
            data.send_time_end = args.send_time_end.format('HH:mm')
        if (args.send_datetime_start !== "")
            data.send_datetime_start = args.send_datetime_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (args.send_datetime_end !== "")
            data.send_datetime_end = args.send_datetime_end.format('YYYY-MM-DDTHH:mm:ss.SSS')


        if (args.category_id) {
            // const ids = args.category_id.map(network => network.value)
            data.category_id = args.category_id.map(item => item.value)
        }

        if (args.network_integration_id) {
            // const ids = args.network_integration_id.map(network => network.value)
            data.network_integration_id = args.network_integration_id.map(item => item.value)
        }


        console.debug(data)

        apiClient.get(endpoints.offerReporting, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const offerReportingService = {
    getItems,
}