import {useState, useCallback} from 'react';
import useApi from '../hooks/useApi';
import {useDispatch} from 'react-redux';
import {setSnack} from '../store/actions/snack';

const useDeleteMultipleData = (apiCall, selectedItems, handleRefreshItems, setSelectedItems) => {
    const dispatch = useDispatch();
    const [deleteItems, setDeleteMultipleItems] = useState(null);
    const deleteItemsApi = useApi(apiCall);

    const handleOpenDeleteMultipleItemsModal = () => setDeleteMultipleItems(true);
    const handleCloseDeleteMultipleItemsModal = () => setDeleteMultipleItems(null);

    const handleDeleteMultipleItems = useCallback(() => {
        deleteItemsApi.makeRequest(
            selectedItems,
            (response) => {
                handleCloseDeleteMultipleItemsModal();
                handleRefreshItems()
                setSelectedItems({})
                dispatch(setSnack("Items Deleted Successfully", "success"));
            },
            (error) => {
                dispatch(setSnack(error.message, "error"));
            }
        );
    }, [selectedItems]);

    return {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi
    };
};

export default useDeleteMultipleData;
