import React, {useCallback, useEffect, useState} from "react";
import {Autocomplete, Box, CircularProgress, Paper, TextField} from "@mui/material";
import CustomTextField from "./CustomTextField";
import useDebounce from "../hooks/useDebounce";
import useApi from "../hooks/useApi";

const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const CustomAPIAutocompleteSelectField = (
    {
        label,
        value,
        onChange,
        apiCall,
        error = null,
        showHelperText = true,
        variant = "standard",
        multiple = false,
        sortBy = "updated_at",
        sortOrder = "desc",
        setOptionLabel = (item) => item.name,
        searchField = "name",
        apiFilters = {},
        additionalOptionsFields = []
    }
) => {
    const api = useApi(apiCall)

    const [pagination, setPagination] = useState({
        page: 1,
        totalPages: 0
    });


    const [paginationRequestSettings, setPaginationRequestSettings] = useState({
        page: -1,
        per_page: 10,
    })

    const [sortRequestSettings, setSortRequestSettings] = useState({
        sortOrder: sortOrder,
        sortBy: sortBy
    })

    const [searchValue, setSearchValue] = useState("");
    const debouncedSearchValue = useDebounce(searchValue, 300)
    const [options, setOptions] = useState([]);
    const [listBoxOpen, setListBoxOpen] = useState(false)

    const getOptions = useCallback(() => {
        console.debug("get Options", apiFilters)
        api.makeRequest(
            {
                ...paginationRequestSettings,
                ...sortRequestSettings,
                ...apiFilters,
                [searchField]: debouncedSearchValue
            },
            (response) => {
                console.debug(response)
                setOptions((prev) => {
                        const updatedOptions = [];

                        if (paginationRequestSettings.page !== 1) {
                            updatedOptions.push(...prev);
                        }

                        updatedOptions.push(...response.data.items.map(item => {
                            console.debug(additionalOptionsFields)
                            const additionalFields = additionalOptionsFields.reduce((fields, field) => {
                                fields[field] = getNestedValue(item, field);
                                return fields;
                            }, {});

                            return {
                                key: setOptionLabel(item),
                                value: item.id,
                                ...additionalFields
                            };
                        }));

                        console.debug(updatedOptions);

                        return updatedOptions;
                    }
                );
                setPagination({
                    page: response.data.page,
                    totalPages: response.data.pages
                })
                if (response.data.pages > 1 && paginationRequestSettings.page === 1) {
                    setPaginationRequestSettings((prev) => ({
                        ...prev,
                        page: 2
                    }));
                }
            }
        )
    }, [setOptions, debouncedSearchValue, paginationRequestSettings, sortRequestSettings, api, apiFilters])

    useEffect(() => {
        if (listBoxOpen)
            getOptions()
    }, [listBoxOpen, paginationRequestSettings])

    useEffect(() => {
        setPaginationRequestSettings((prev) => ({
            ...prev,
            page: 1
        }));
    }, [debouncedSearchValue]);

    const handleListBoxOpen = useCallback(() => {
        setListBoxOpen(true)
        setPaginationRequestSettings((prev) => ({
            ...prev,
            page: 1
        }));
    }, [setPaginationRequestSettings])

    const handleListBoxClose = () => {
        setListBoxOpen(false)
    }


    const handleInputChange = useCallback((event, value, reason) => {
        if (reason === "input") {
            setSearchValue(value)
        }
        setSearchValue(value)
    }, [])

    const handleChange = (event, value) => {
        onChange(value)
    }

    const fetchNextPage = useCallback(() => {
        if (pagination.page < pagination.totalPages) {
            setPaginationRequestSettings((prev) => ({
                ...prev,
                page: pagination.page + 1
            }))
        }
    }, [pagination, setPaginationRequestSettings])

    const handleListBoxScroll = useCallback((event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.target;
        if (scrollHeight - scrollTop <= clientHeight) {
            fetchNextPage()
        }
    }, [fetchNextPage]);

    // console.debug(pagination, paginationRequestSettings)

    return <Autocomplete
        value={value}
        options={options}
        loading={api.loading}
        multiple={multiple}
        // freeSolo
        filterOptions={(options, state) => options}
        getOptionLabel={(option) => option.key}
        isOptionEqualToValue={(option, value) => option.value === value.value}

        onOpen={handleListBoxOpen}
        onClose={handleListBoxClose}
        onChange={handleChange}
        onInputChange={handleInputChange}

        renderInput={(params) => (
            <CustomTextField
                {...params}
                label={label}
                error={!!error}
                helperText={showHelperText ? (error ? error.message : " ") : null}
                variant={variant}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {api.loading ? <CircularProgress color={"primary"} size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}

        ListboxProps={{
            onScroll: handleListBoxScroll,
            maxHeight: 100
        }}
        //         PaperComponent={({ children }) => (
        //   <Paper style={{ maxHeight: 300, overflow: 'auto' }}>{children}</Paper>
        // )}
    />
}

export default CustomAPIAutocompleteSelectField