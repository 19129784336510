import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    token: null,
    loading: false,
    isInitialAuthFinished: false
};

const loginStart = (state, action) => {
    return updateObject( state, {
        loading: true
     } );
};

const loginFailure = (state, action) => {
    return updateObject( state, {
        loading: false
     } );
};

const loginSuccess = (state, action) => {
     return updateObject(state, { loading: false, token: action.token});
};

const logout = (state, action) => {
    return updateObject(state, { token: null});
};

const finishInitialAuth = (state, action) => {
    return updateObject(state, { isInitialAuthFinished: true});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOGIN_START: return loginStart(state, action);
        case actionTypes.LOGIN_FAILURE: return loginFailure(state, action);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.FINISH_INITIAL_AUTH: return finishInitialAuth(state, action)
        default:
            return state;
    }
};

export default reducer;