import React, {useEffect} from 'react';
import {Box, IconButton} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import {Link, useNavigate} from 'react-router-dom';
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit, Settings} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {mailingListService} from "../api/services/mailingListService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import AddMailingListModal from "../components/MailingList/MailingListModal/AddMailingListModal";
import EditMailingListModal from "../components/MailingList/MailingListModal/EditMailingListModal";
import {verticalService} from "../api/services/verticalService";
import {brandService} from "../api/services/brandService";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import useApi from "../hooks/useApi";
import {partnerService} from "../api/services/partnerService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";

const MailingLists = () => {
    const navigate = useNavigate();
    const urlParams = useUrlParams()
    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        mailingListService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name"
            },
            is_active: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Active", value: true},
                    {key: "Inactive", value: false}
                ],
                label: "Status"
            },
            vertical_id: {
                type: filtersElementsTypes.MULTISELECT_API,
                apiCall: verticalService.getItems,
                label: "Verticals"
            },
            brand_id: {
                label: "From Email (Brands)",
                apiCall: brandService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
                searchField: "from_email",
                setOptionLabel: (item) => item.from_email,
            },
            partner_id: {
                type: filtersElementsTypes.MULTISELECT_API,
                apiCall: partnerService.getItems,
                label: "Partners"
            }
        },
        {},
        {},
        urlParams.queryParams
    )

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        mailingListService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        mailingListService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        mailingListService.getItems,
        urlParams.queryParams
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])

    const switchButtonApi = useApi(
        mailingListService.updateStatus
    )

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: "250px"
        },
        {
            id: 'vertical.name',
            numeric: false,
            disablePadding: false,
            label: 'Vertical',
            nested: true,
            width: "150px"
        },
        {
            id: 'brand.from_email',
            numeric: false,
            disablePadding: false,
            label: 'From Email',
            nested: true,
            width: "240px"
        },
        {
            id: 'partner.name',
            numeric: false,
            disablePadding: false,
            label: 'Partner',
            nested: true,
            width: "130px"
        },
        {
            id: 'campaign_tag',
            numeric: false,
            disablePadding: false,
            label: 'Campaign Tag',
            width: "270px"
        },
        {
            id: 'is_active',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            width: 80,
            element: (item) => (<CustomSwitchWithConfirmation
                title={"Status Change"}
                description={"Are you sure you want to change this mailingList?"}
                item={item}
                api={switchButtonApi}
                handleSubmit={(selectedItem, onSuccess) => {
                    switchButtonApi.makeRequest(
                        {
                            ...selectedItem,
                            is_active: !selectedItem.is_active
                        },
                        () => {
                            handleRefreshItems()
                            onSuccess()
                        }
                    )
                }}
            />)
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "120px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "120px"
        },
        {
            element: (item) => (
                <IconButton
                    component={Link}
                    to={`/lists/${item.id}/configs`}
                    onClick={(e) => {
                        // Prevent default action if the click is middle-click or ctrl/cmd+click
                        if (e.ctrlKey || e.metaKey || e.button === 1) {
                            return;
                        }
                        e.preventDefault();
                        navigate(`/lists/${item.id}/configs`);
                    }}
                >
                    <Settings/>
                </IconButton>
            ),
            width: 48
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    return <>
        <Box>
            <CustomAppBar
                handleModalOpen={handleOpenCreateItemModal}
                addButtonText={"Create List"}
                selectedItems={selectedItems}
                onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditMailingListModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddMailingListModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete List"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> list?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete List"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> list(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
}

export default MailingLists