import {useSortData} from "../../hooks/useSortData";
import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Typography, useTheme
} from "@mui/material";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableFooter from "./EnhancedTableFooter";
import EnhancedTableBody from "./EnhancedTableBody";
import EnhancedTableLoader from "./EnhancedTableLoader";
import TablePaginationActions from "./TablePaginationActions";
import React from "react";
import CustomTable from "./CustomTable";

const EnhancedTable = (
    {
        table,
        data: {
            items,
            sort,
            pagination,
            api
        },
        headCells,
        searchTerm = "",
        rowsPerPage = [10, 25, 50],
        rowHeight = null,
        disableSelecting = false,
        totals= false
    }
) => {
    let emptyRows = 0

    if (items.length < rowsPerPage[0])
        emptyRows = rowsPerPage[0] - items.length

    return (
        <CustomTable
            loading={api.loading}
            rowHeight={rowHeight}
            rowsPerPage={rowsPerPage}
            totals={!!totals}
            header={(
                <EnhancedTableHead
                    numSelected={table.selected.length}
                    sort={sort}
                    onSelectAllClick={table.handleSelectAllClick}
                    handleSort={sort.handleSort}
                    headCells={headCells}
                    rowCount={items.length}
                    table={table}
                    disableSelecting={disableSelecting}
                    totals={totals}
                />
            )}
            body={(
                <EnhancedTableBody
                    disableSelecting={disableSelecting}
                    table={table}
                    headCells={headCells}
                    items={items}
                    emptyRows={emptyRows}
                    searchTerm={searchTerm}
                    rowHeight={rowHeight}
                    rowsPerPage={rowsPerPage}
                />
            )}
            footer={(
                <TablePagination
                    rowsPerPageOptions={rowsPerPage}
                    colSpan={headCells.length}
                    component={"div"}
                    count={pagination.paginationInfo.total}
                    rowsPerPage={pagination.values.per_page}
                    page={pagination.values.page - 1}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                    }}
                    onPageChange={pagination.handleChangePage}
                    onRowsPerPageChange={(event) => pagination.handleChangePerPage(parseInt(event.target.value, 10))}
                    ActionsComponent={TablePaginationActions}
                />
            )}
        />
    );
}

export default EnhancedTable