import React from 'react';
import {IconButton} from "@mui/material";
import {Link, LinkOff} from "@mui/icons-material";
import {MAROPOST_ID} from "../../utils/constants";
import {accessExternalLinkInNewTab} from "../../utils/browserFunctions";

const ExternalLinkButton = ({campaign_data}) => {

    const accessESPCampaignPage = () => {
        let url = null
        if (campaign_data.esp_integration.esp_id === MAROPOST_ID) {
            url = `https://app.maropost.com/accounts/${campaign_data.esp_integration.maropost_external_account_id}/campaigns/${campaign_data.external_id}`
        }

        if (url){
            accessExternalLinkInNewTab(url)
        }
    }

    return (
        <IconButton onClick={() => accessESPCampaignPage()} disabled={!campaign_data.external_id || campaign_data.external_id === "N/A"}>
            {campaign_data.external_id ? <Link/> : <LinkOff/>}
        </IconButton>
    );
};

export default ExternalLinkButton;