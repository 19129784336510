import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import DeleteVertical from "../DeleteVertical";
import {verticalService} from "../../../api/services/verticalService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";

const schema = yup.object().shape({
    vertical_name: yup.string().required('Required field').min(1, 'Required'),
});

const EditVerticalModal = (
    {
        setSnack,
        handleClose,
        handleRefreshItems,
        item
    }
) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        formState
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            vertical_name: item.name
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        verticalService.updateItem(data.vertical_name, item.id)
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={true}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Vertical"}/>
            <CustomModalBody>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12}>
                            <ControlledTextField control={control} name={"vertical_name"} label={"Name"}/>
                        </Grid>
                    </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Vertical"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditVerticalModal);