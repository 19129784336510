import dayjs from "dayjs";

export const format = (value, pattern) => {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, _ => v[i++]);
}

export const formatDecimal = (value) => {
    const strValue = value.toString();
    const length = strValue.length;
    return length > 2 ? strValue.slice(0, -2) + "." + strValue.slice(-2) : "0." + strValue.padStart(2, "0");
}

export const formatLeadingZeros = (number) => {
    // Convert the number to a string
    let numberString = number.toString();

    // Calculate the number of leading zeros needed
    let zerosNeeded = 6 - numberString.length;

    // Add leading zeros if necessary
    for (let i = 0; i < zerosNeeded; i++) {
        numberString = '0' + numberString;
    }

    return numberString;
}

export const extractNameFromPath = (path) => {
    const parts = path.split('/'); // Split the path into its parts
    const target = parts[parts.length - 1]; // Get the last part of the path

    if (target) {
        // Capitalize the first letter and, if it ends with 's', remove the last 's' for plural form
        return target.charAt(0).toUpperCase() + target.slice(1)
    }
    return null; // or a default value if not found
}

export const getNestedValue = (obj, path) => {
    return path.split('.').reduce((currentObject, key) => {
        return currentObject && currentObject[key] !== undefined ? currentObject[key] : undefined;
    }, obj);
}

export const simplifyParams = (params) => {
    const simplifiedParams = {};

    for (const key in params) {
        if (Array.isArray(params[key])) {
            simplifiedParams[key] = params[key].map(item => item.value);
        } else if ( dayjs.isDayjs(params[key])) {
            simplifiedParams[key] = params[key].valueOf(); // Convert datetime to timestamp
        } else if (params[key]?.value !== undefined) {
            simplifiedParams[key] = params[key].value;
        } else {
            simplifiedParams[key] = params[key];
        }
    }
    return simplifiedParams;
};

export const transformParams = (params) => {
    const transformedParams = {};

    for (const key in params) {
        if (Array.isArray(params[key])) {
            transformedParams[key] = params[key].map(item => ({value: parseInt(item)}));
        } else if (params[key] === 'true') {
            transformedParams[key] = true;
        } else if (params[key] === 'false') {
            transformedParams[key] = false;
        } else if (key.endsWith("_id")) {
            transformedParams[key] = [{value: parseInt(params[key])}];
        } else {
            transformedParams[key] = params[key];
        }
    }
    return transformedParams;
};

// Function to merge the lists
export const mergeLists = (valuesList, detailsList) => {
    // Create a map from the detailsList for quick lookup
    const detailsMap = new Map(detailsList.map(detail => [detail.id, detail.name]));
    console.debug(valuesList)
    // Iterate over the valuesList and add the corresponding name
    return valuesList.map(item => {
        return {
            ...item,
            key: detailsMap.get(item.value)
        };
    });
};