import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {brandService} from "../../../api/services/brandService";
import {option} from "../../../utils/selectFIeldHelpers";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import {mailingListService} from "../../../api/services/mailingListService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {verticalService} from "../../../api/services/verticalService";
import {partnerService} from "../../../api/services/partnerService";

const schema = yup.object().shape({
    name: yup.string().required('Required field'),
    campaign_tag: yup.string().required('Required field'),
    partner: option.required('Required field'),
    brand: option.required('Required field'),
    vertical: option.required('Required field'),
});

const EditMailingListModal = ({
                                  setSnack,
                                  open,
                                  item,
                                  handleClose,
                                  handleRefreshItems,
                              }) => {
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: item.name,
            campaign_tag: item.campaign_tag,
            partner: {
                key: item.partner.name,
                value: item.partner.id
            },
            brand: item.brand ? {
                key: item.brand.name + " - " + item.brand.from_email,
                value: item.brand.id
            } : null,
            vertical: item.vertical ? {
                key: item.vertical.name,
                value: item.vertical.id
            } : null
        }
    });


    const onSuccess = (data) => {
        setLoading(true);

        mailingListService.updateItem(
            item.id,
            data.name,
            data.campaign_tag,
            data.partner.value,
            data.brand.value,
            data.vertical.value
        )
            .then((response) => {
                setSnack(response.message, "success");
                setLoading(false);
                handleClose();
                handleRefreshItems();
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error");
                setLoading(false);
            });
    }

    const onError = (error) => {
        console.debug(error);
    }

    return (
        <CustomModal
            open={true}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            maxWidth={"md"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit List"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"campaign_tag"} label={"Campaign Tag"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"brand"}
                            label={"Brand"}
                            apiCall={brandService.getItems}
                            searchField={"from_email"}
                            setOptionLabel={(item) => item.from_email}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control} name={"vertical"}
                            label={"Vertical"}
                            apiCall={verticalService.getItems}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control} name={"partner"}
                            label={"Partner"}
                            apiCall={partnerService.getItems}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save List"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditMailingListModal);
