import React, {useState} from 'react';
import * as yup from "yup";
import {option} from "../../../utils/selectFIeldHelpers";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {mailingListService} from "../../../api/services/mailingListService";
import {setSnack} from "../../../store/actions/snack";
import {connect} from "react-redux";
import {mailingListGroupService} from "../../../api/services/mailingListGroupService";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import SubmitButton from "../../SubmitButton";
import MailingListsGroupsAssociation from "../MailingListsGroupAssociation/MailingListsGroupsAssociation";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";

const schema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
});

const AddMailingListsGroupModal = ({setSnack, handleClose, setCreatedMailingListsGroup, open, handleRefreshItems}) => {
    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
        }
    });

    const onSuccess = async (data) => {
        setLoading(true);

        try {
            setLoading(true);
            const mailingListGroupResponse = await mailingListGroupService.createItem(data.name);
            setCreatedMailingListsGroup(mailingListGroupResponse.data)
            setSnack(mailingListGroupResponse.message, "success");
            reset()
            handleRefreshItems()
            handleClose()
        } catch (error) {
            setSnack(error.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const onError = (error) => {
        console.debug(error);
    }
    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Group"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                {/*<Grid container spacing={2} sx={{mt: 3}}>*/}
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create Group"}
                        loading={loading}
                    />
                </Grid>

                {/*</Grid>*/}
            </CustomModalFooter>


            {/*</Container>*/}
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddMailingListsGroupModal);