import React from 'react';
import {Box, IconButton, Paper, Typography} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import ArrowForwardIcon  from "@mui/icons-material/ArrowForward";

const AutomatedCampaignsPreview = ({itemsNo}) => {
    return (
        <Paper sx={{
            my: 2,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            height: 48,
            width: 350,
            borderRadius: "8px",
            backgroundColor: "primary.light",
        }}>
            <Box sx={{flexGrow: 1}}>
                {/* Empty Box to take up space */}
            </Box>
            <Typography variant={"button"}>
                {itemsNo}&nbsp;
            </Typography>
            <Typography variant={"menuItem"}>
                Campaign(s) Created Automatically
            </Typography>
            <MuiLink component={RouterLink} to="/campaign?creation_method=2&status=-2">
                <IconButton color="primary">
                    <ArrowForwardIcon  />
                </IconButton>
            </MuiLink>
        </Paper>
    );
};

export default AutomatedCampaignsPreview;