import React, {useState} from 'react';
import CustomModalHeader from "../CustomModal/CustomModalHeader";
import CustomModalFooter from "../CustomModal/CustomModalFooter";
import CustomModalBody from "../CustomModal/CustomModalBody";
import CustomModal from "../CustomModal/CustomModal";
import useManualScheduleStepper from "../../hooks/useManualScheduleStepper";
import ManualScheduleSelectMailingListGroups from "./ManualScheduleSelectMailingListGroups";
import ManualScheduleSelectMailingLists from "./ManualScheduleSelectMailingLists";
import ManualScheduleHelperModalDescription from "./ManualScheduleHelperModalDescription";
import ManualScheduleHelperModalSettings from "./ManualScheduleHelperModalSettings";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {option} from "../../utils/selectFIeldHelpers";
import {yupResolver} from "@hookform/resolvers/yup";
import {brandService} from "../../api/services/brandService";
import {useDispatch} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import {automatedSchedulingConfigService} from "../../api/services/automatedSchedulingConfigsService";

const schema = yup.object().shape({
    name: yup.string().required('Required field'),
    config: option.required('Required field'),
    content_lookback_days: yup.number().typeError('Must be a number').required('Required field'),
    min_sends: yup.number().typeError('Must be a number').required('Required field'),
    excluded_offers_lookback_days: yup.number().typeError('Must be a number').required('Required field'),
    start_datetime: yup.object().required('Required field'),
    send_time: yup.object().required('Required field')
});

const CreateAutomatedSchedulingConfigModal = (
    {
        handleClose,
        open,
        handleRefreshItems
    }
) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [selectedMailingLists, setSelectedMailingLists] = useState({})
    const [selectedMailingListGroups, setSelectedMailingListGroups] = useState({})
    const {handleSubmit, control, reset} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            config: null,
            name: "",
            content_lookback_days: "",
            excluded_offers_lookback_days: "",
            start_datetime: null,
            end_datetime: null,
            send_time: null,
            min_sends: null
        }
    });

    const onSuccess = (data) => {
        setLoading(true);
        automatedSchedulingConfigService.createItem(
            data.name,
            Object.keys(selectedMailingLists),
            data.content_lookback_days,
            data.excluded_offers_lookback_days,
            data.start_datetime,
            data.end_datetime,
            data.config.key,
            data.send_time,
            data.min_sends
        )
            .then((response) => {
                dispatch(setSnack(response.message, "success"))
                setLoading(false);
                handleClose();
                reset()
                handleRefreshItems();
            })
            .catch((error) => {
                console.debug(error)
                dispatch(setSnack("Invalid Parameters", "error"))
                setLoading(false);
            });
    }

    const onError = (data) => {
        console.debug(data)
    }

    const stepper = useManualScheduleStepper(
        {
            handleClose,
            selectedMailingLists,
            selectedMailingListGroups,
            handleComplete: () => {
                handleSubmit(onSuccess, onError)()
            },
            loading
        })

    let mainContainer;

    const handleSelectMailingLists = (newSelectedMailingLists, newState) => {
        const updatedItems = {...selectedMailingLists}
        if (newState) {
            newSelectedMailingLists.forEach(item => {
                updatedItems[item.id] = item
            })
        } else {
            newSelectedMailingLists.forEach(item => {
                if (updatedItems.hasOwnProperty(item.id)) {
                    delete updatedItems[item.id]
                }
            })
        }
        setSelectedMailingLists(updatedItems)
    }

    const handleSelectMailingListGroups = (newSelectedMailingListGroups, newState) => {
        const updatedSelectedMailingListGroups = {...selectedMailingListGroups};
        const newSelectedMailingLists = []

        if (newState) {
            // If newState is true, add all items to the dictionary
            newSelectedMailingListGroups.forEach(item => {
                updatedSelectedMailingListGroups[item.id] = item;
                newSelectedMailingLists.push(...updatedSelectedMailingListGroups[item.id].mailing_lists)
            });

        } else {
            newSelectedMailingListGroups.forEach(item => {
                if (updatedSelectedMailingListGroups.hasOwnProperty(item.id)) {
                    newSelectedMailingLists.push(...updatedSelectedMailingListGroups[item.id].mailing_lists)
                    delete updatedSelectedMailingListGroups[item.id]
                }
            });
        }
        handleSelectMailingLists(newSelectedMailingLists, newState)
        setSelectedMailingListGroups(updatedSelectedMailingListGroups);
    }

    // const handleChangeSelectedSettings = (newSetting) => {
    //     console.debug(newSetting)
    //     const updatedSelectedSettings = {...selectedSettings, ...newSetting}
    //     setSelectedSettings(updatedSelectedSettings)
    // }

    const description = <ManualScheduleHelperModalDescription noOfItems={Object.keys(selectedMailingLists).length}/>


    if (stepper.activeStep === 0) {
        mainContainer = <ManualScheduleSelectMailingListGroups
            handleSelectMailingListGroups={handleSelectMailingListGroups}
            selectedMailingListGroups={selectedMailingListGroups}
            description={description}
        />
    } else if (stepper.activeStep === 1) {
        mainContainer = <ManualScheduleSelectMailingLists
            handleSelectMailingLists={handleSelectMailingLists}
            selectedMailingLists={selectedMailingLists}
            description={description}
        />
    } else {
        mainContainer = <ManualScheduleHelperModalSettings
            // handleChangeSelectedSettings={handleChangeSelectedSettings}
            control={control}
            selectedMailingLists={selectedMailingLists}
            description={description}
        />
    }

    return (
        <CustomModal
            open={open}
            handleClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            containerProps={{
                maxWidth: "sm"
            }}
        >
            <CustomModalHeader handleClose={handleClose} title={"Automated Scheduling Config"}/>
            <CustomModalBody>
                {stepper.stepperHTML}
                {mainContainer}
            </CustomModalBody>
            <CustomModalFooter justifyContent={"flex-end"}>
                {stepper.backButtonHTML}
                {stepper.nextButtonHTML}
            </CustomModalFooter>
        </CustomModal>
    );
};

export default CreateAutomatedSchedulingConfigModal;