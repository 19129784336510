import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {brandService} from "../../../api/services/brandService";
import {option} from "../../../utils/selectFIeldHelpers";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import ExternalImage from "../../ExternalImage";
import BrandModalImage from "./BrandModalImage";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {espIntegrationService} from "../../../api/services/espIntegrationService";

const schema = yup.object().shape({
    brand_name: yup.string().required('Required field'),
    from_email: yup.string().required('Required field'),
    esp_integration: option.required('Required field'),
    reply_to: yup.string().required('Required field'),
    logo_url: yup.string().required('Required field'),
    external_brand_id: yup.string().required('Required field'),
});


const EditBrandModal = ({setSnack, open, handleClose, handleRefreshItems, item}) => {
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            brand_name: item.name,
            from_email: item.from_email,
            esp_integration: item.esp_integration ? {
                key: item.esp_integration.name,
                value: item.esp_integration.id
            } : null,
            reply_to: item.reply_to,
            logo_url: item.logo_url,
            external_brand_id: item.external_brand_id
        }
    });

    const onSuccess = (data) => {
        setLoading(true);

        brandService.updateItem(
            item.id,
            data.brand_name,
            data.from_email,
            data.esp_integration.value,
            data.reply_to,
            data.logo_url,
            data.external_brand_id
        )
            .then((response) => {
                setSnack(response.message, "success");
                setLoading(false);
                handleClose();
                handleRefreshItems();
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error");
                setLoading(false);
            });
    }

    const onError = (error) => {
        console.debug(error);
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Brand"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"brand_name"} label={"Brand Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"esp_integration"}
                            label={"Platform Integration"}
                            apiCall={espIntegrationService.getItems}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"from_email"} label={"From Email"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"reply_to"} label={"Reply To"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"external_brand_id"} label={"External Brand ID"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"logo_url"} label={"Logo URL"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <BrandModalImage control={control}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Brand"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditBrandModal);
