import React from 'react';
import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TablePagination,
    TableRow, useTheme
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";

function FirstPageIcon() {
    return null;
}

function LastPageIcon() {
    return null;
}

const MailingListGroupAssociationTable = ({mailingLists, pagination, fetchData, mailingListsGroupsAssociations, handleDelete}) => {
    const theme = useTheme();

    const handleActionClick = (id) => {
        // Placeholder for whatever action you need to perform
        console.debug(`Action clicked for row with id ${id}`);
    };

    const handleNextPageClick = () => {

        fetchData({itemsPerPage: pagination.per_page, currentPage: pagination.page + 1});
    };

    const handlePreviousPageClick = () => {
        fetchData({itemsPerPage: pagination.per_page, currentPage: pagination.page - 1});
    };

    const handleFirstPageClick = () => {
        fetchData({itemsPerPage: pagination.per_page, currentPage: 1});
    };

    const handleLastPageClick = () => {

        fetchData({itemsPerPage: pagination.per_page, currentPage: pagination.pages});
    };

    const handleChangePage = (event, newPage) => {
        fetchData({itemsPerPage: pagination.per_page, currentPage: newPage});
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>Name</TableCell>
                        {/*<TableCell align="right"></TableCell> /!* Empty cell for the action column *!/*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mailingListsGroupsAssociations.length < 1 ? (
                        <TableRow>
                            <TableCell colSpan={2} align="center">No data</TableCell>
                        </TableRow>
                    ) : (
                        mailingListsGroupsAssociations.map((row, index) => (
                            <React.Fragment key={row.id}>
                                <TableRow>
                                    <TableCell component="th" scope="row">{row.mailing_list.name}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleDelete(row.id)} aria-label="delete">
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[pagination.per_page]}
                component="div"
                count={pagination.total || 0}
                rowsPerPage={pagination.per_page}
                page={pagination.page - 1}
                onPageChange={handleChangePage}
                ActionsComponent={() => (
                    <div style={{flexShrink: 0, marginLeft: theme.spacing(2.5)}}>
                        <IconButton
                            onClick={handleFirstPageClick}
                            disabled={pagination.page === 1}
                            aria-label="first page"
                        >
                            <FirstPageIcon/>
                        </IconButton>
                        <IconButton
                            onClick={handlePreviousPageClick}
                            disabled={pagination.page === 1}
                            aria-label="previous page"
                        >
                            <KeyboardArrowLeft/>
                        </IconButton>
                        <IconButton
                            onClick={handleNextPageClick}
                            disabled={pagination.page >= pagination.pages}
                            aria-label="next page"
                        >
                            <KeyboardArrowRight/>
                        </IconButton>
                        <IconButton
                            onClick={handleLastPageClick}
                            disabled={pagination.page >= pagination.pages}
                            aria-label="last page"
                        >
                            <LastPageIcon/>
                        </IconButton>
                    </div>
                )}
            />
        </TableContainer>
    );
};
export default MailingListGroupAssociationTable;