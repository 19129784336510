import React from 'react';
import {useWatch} from "react-hook-form";
import ExternalImage from "../../ExternalImage";
import {Box} from "@mui/material";
import brandLogoImagePlaceholder from "../../../assets/images/brandLogoImagePlaceholder.png"

const BrandModalImage = ({control}) => {
    const logo_url = useWatch({
            control,
            name: "logo_url"
        }
    )

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                height: '200px',
                width: "100%"
            }}
        >
            <ExternalImage
                imageSource={logo_url || brandLogoImagePlaceholder}
                alt="Brand Logo"
                height={"100%"}
                width={"100%"}
            />
        </Box>
    );
};

export default BrandModalImage;