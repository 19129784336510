import {connect} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import ButtonWithConfirmation from "../ButtonWithConfirmation";
import {useState} from "react";
import {contentService} from "../../api/services/contentService";

const DeleteContent = ({content, fetchContents}) => {
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)

        contentService.deleteContent(content.id)
            .then((response) => {
                setSnack(response.message, "success")
                fetchContents()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    return (
        <ButtonWithConfirmation loading={loading} onConfirm={onClick} text={"Delete"}/>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(DeleteContent);