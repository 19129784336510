import React, {useState} from 'react';
import {Box} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import useModal from "../hooks/useModal";
import ManualScheduleHelperModal from "../components/ManualSchedule/ManualScheduleHelperModal";
import useResource from "../hooks/useResource";
import {contentService} from "../api/services/contentService";
import useTable from "../hooks/useTable";
import ManualScheduleTable from "../components/ManualSchedule/ManualScheduleTable";
import useApi from "../hooks/useApi";
import {campaignService} from "../api/services/campaignService";
import {useDispatch} from "react-redux";
import {setSnack} from "../store/actions/snack";
import {useNavigate} from "react-router-dom";
import {endpoints} from "../api/endpoints";
import {routes} from "../utils/routes";
import useAvailableContents from "../hooks/useAvailableContents";

const ManualSchedule = () => {
    const navigate = useNavigate();
    const schedulingHelperModal = useModal()
    const dispatch = useDispatch()

    const [mailingLists, setMailingLists] = useState({})
    const [campaigns, setCampaigns] = useState([])
    const [errors, setErrors] = useState(true)

    const campaignApi = useApi(campaignService.createItem)

    const handleSchedulingHelperCompleted = (selectedMailingLists, selectedSettings) => {
        console.debug(selectedMailingLists, selectedSettings)
        const updatedCampaigns = []
        Object.values(selectedMailingLists).forEach((mailingList) => {
            const configs = selectedMailingLists[mailingList.id].configs
            let config = null

            configs.forEach((conf) => {
                if (conf.name === selectedSettings.config.key) {
                    config = {
                        "key": conf.name,
                        "value": conf.id
                    }
                }
            })
            updatedCampaigns.push({
                id: mailingList.id,
                config: config,
                content: selectedSettings.content,
                sendTime: selectedSettings.sendTime,
                mailing_list: mailingList,
                offer: {value: selectedSettings.content["offer.id"], key: selectedSettings.content["offer.name"]}
            })
        })
        setMailingLists(selectedMailingLists)
        setCampaigns(updatedCampaigns)
    }
    console.debug(campaigns)
    const getMailingListName = (mailingListId) => {
        // console.debug(mailingListId)
        const mailingList = mailingLists[mailingListId]
        if (mailingList) {
            return mailingList.name
        }
        return null
    }

    const getMailingListConfigs = (mailingListId) => {
        const mailingList = mailingLists[mailingListId]
        if (mailingList) {
            return mailingList.configs.map((config) => {
                return {
                    key: config.name,
                    value: config.id
                }
            })
        }
        return []
    }

    const handleChangeSettings = (campaignId, settingsName, newValue) => {
        // Find the index of the campaign with the specified ID
        const campaignIndex = campaigns.findIndex(campaign => campaign.id === campaignId);

        if (campaignIndex === -1) {
            return;
        }
        //
        // // Check if settingsName is either 'config' or 'content'
        // if (settingsName !== 'config' && settingsName !== 'content') {
        //     console.debug('Invalid settings name');
        //     return;
        // }

        // Update the specified setting of the campaign
        const updatedCampaigns = [...campaigns]
        updatedCampaigns[campaignIndex][settingsName] = newValue;
        if (settingsName === "offer") {
            updatedCampaigns[campaignIndex].content = null
        }
        setCampaigns(updatedCampaigns)
        // Log or return the updated campaigns array for verification
        // console.debug('Updated campaigns:', campaigns);
    }

    // console.debug(mailingLists)
    // console.debug(campaigns)

    const handleSubmit = () => {
        let error = [];

        if (campaigns.some(campaign => campaign.config === null)) {
            error.push("configs");
        }

        if (campaigns.some(campaign => campaign.content === null)) {
            error.push("contents");
        }

        if (error.length) {
            dispatch(setSnack(`Please fill in all ${error.join(", ")} for campaigns!`, "error"));
            return
        }

        const requestData = []

        campaigns.forEach((campaign) => {
            requestData.push({
                config_id: campaign.config.value,
                content_id: campaign.content.value,
                send_time: campaign.sendTime.format('YYYY-MM-DDTHH:mm:ss.SSS')
            })
        })

        campaignApi.makeRequest(
            requestData,
            () => {
                dispatch(setSnack("Campaigns Prepared for Schedule!", "success"))
                navigate(routes.campaigns)
            })

    }

    return (
        <>
            <Box>
                <CustomAppBar handleModalOpen={schedulingHelperModal.handleModalOpen}
                              addButtonText={"Scheduling Helper"}/>
                <Box sx={{pt: 2}}/>
                <ManualScheduleTable
                    campaigns={campaigns}
                    getMailingListName={getMailingListName}
                    getMailingListConfigs={getMailingListConfigs}
                    handleChangeSettings={handleChangeSettings}
                    handleSubmit={handleSubmit}
                    errors={errors}
                />
            </Box>
            <ManualScheduleHelperModal
                open={schedulingHelperModal.modalOpen}
                handleClose={schedulingHelperModal.handleModalClose}

                handleSchedulingHelperCompleted={handleSchedulingHelperCompleted}
            />
        </>
    );
};

export default ManualSchedule;