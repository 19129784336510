import {useCallback, useState} from "react";

const defaultPagination = {
    page: 1,
    per_page: 10,
    paginate: true
}

const usePagination = (initialPagination = {}) => {

    const [values, setValues] = useState({...defaultPagination, ...initialPagination});
    const [paginationInfo, setPaginationInfo] = useState({
        total: -1,
        pages: initialPagination.page || 2,
        page: -1,
        per_page: initialPagination.per_page
    })

    const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
        setValues(prev => ({...prev, per_page: newItemsPerPage}));
    }, [setValues]);

    const handleNextPageClick = useCallback(() => {
        setValues(prev => ({...prev, page: prev.page + 1}));
    }, [setValues]);

    const handlePreviousPageClick = useCallback(() => {
        setValues(prev => ({...prev, page: Math.max(prev.page - 1, 1)}));
    }, [setValues]);

    const handleFirstPageClick = useCallback(() => {
        setValues(prev => ({...prev, page: 1}));
    }, [setValues]);

    const handleLastPageClick = useCallback(() => {
        setValues(prev => ({...prev, page: prev.pages}));
    }, [setValues]);

    const handleChangePage = useCallback((page) => {
        setValues(prev => ({...prev, page}));
    }, [setValues])

    const handleChangePerPage = useCallback((perPage) => {
        setValues(prev => ({...prev, per_page: perPage, page: 1}));
    }, [setValues])

    return {
        values,
        setValues,
        handleItemsPerPageChange,
        handleNextPageClick,
        handlePreviousPageClick,
        handleFirstPageClick,
        handleLastPageClick,
        paginationInfo,
        setPaginationInfo,
        handleChangePage,
        handleChangePerPage
    };
}

export default usePagination;