import {connect} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import ButtonWithConfirmation from "../ButtonWithConfirmation";
import {networkIntegrationService} from "../../api/services/networkIntegrationService";
import {useState} from "react";
import {categoryService} from "../../api/services/categoryService";

const DeleteCategory = ({category, fetchCategories, handleClose, setSnack}) => {
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)

        categoryService.deleteCategory(category.id)
            .then((response) => {
                setSnack(response.message, "success")
                fetchCategories()
                handleClose()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    return (
        <ButtonWithConfirmation loading={loading} onConfirm={onClick} text={"Delete"}/>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(DeleteCategory);