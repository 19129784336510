import React from 'react';
import {Container, Grid, Modal, Paper, useTheme} from "@mui/material";

const CustomModal = ({open, handleClose, containerProps, children, maxWidth="sm", ...props}) => {
    const onClose = (event, reason) => {
        if (reason && reason === "backdropClick"){
            return
        }
        else{
            handleClose()
        }
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            sx={{overflow: "scroll", outline: 0}}
            {...props}
        >
            <Container maxWidth={maxWidth} component={Paper} sx={{my: 4, py: 3}} {...containerProps}>
                <Grid container spacing={3}>

                    {children}
                </Grid>
            </Container>
        </Modal>
    );
};

export default CustomModal;