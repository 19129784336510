import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        console.debug(args)
        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
            segments: args.segments,
            suppressed_segments: args.suppressed_segments
        }

        if (args.template_id) {
            data.template_id = args.template_id.map(option => option.value)
        }

        apiClient.get(`${endpoints.mailingList}/${args.mailing_list_id}/config`, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const createItem = (mailing_list_id, name, template_id, suppressed_segments, segments) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            template_id,
            suppressed_segments,
            segments
        };

        apiClient.post(`${endpoints.mailingList}/${mailing_list_id}/config`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateItem = (mailing_list_id, config_id, name, template_id, suppressed_segments, segments) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
            template_id,
            suppressed_segments,
            segments
        };

        apiClient.patch(`${endpoints.mailingList}/config/${config_id}`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(`${endpoints.mailingList}${endpoints.config}/${itemId}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post("/api/mailing-list/config/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const configService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems
};
