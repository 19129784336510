import {useState, useCallback} from 'react';
import useApi from '../hooks/useApi';
import {useDispatch} from 'react-redux';
import {setSnack} from '../store/actions/snack';

const useRetryMultipleData = (apiCall, selectedItems, handleRefreshItems, setSelectedItems) => {
    const dispatch = useDispatch();
    const [retryItems, setRetryMultipleItems] = useState(null);
    const retryItemsApi = useApi(apiCall);

    const handleOpenRetryMultipleItemsModal = () => setRetryMultipleItems(true);
    const handleCloseRetryMultipleItemsModal = () => setRetryMultipleItems(null);

    const handleRetryMultipleItems = useCallback(() => {
        retryItemsApi.makeRequest(
            selectedItems,
            (response) => {
                handleCloseRetryMultipleItemsModal();
                handleRefreshItems()
                setSelectedItems({})
                dispatch(setSnack(response.message, "success"));
            },
            (error) => {
                dispatch(setSnack(error.message, "error"));
            }
        );
    }, [selectedItems]);

    return {
        retryItems,
        handleOpenRetryMultipleItemsModal,
        handleCloseRetryMultipleItemsModal,
        handleRetryMultipleItems,
        retryItemsApi
    };
};

export default useRetryMultipleData;
