import React from 'react';
import {Checkbox, IconButton, TableBody, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import {getNestedValue} from "../../utils/formatText";
import dayjs from "dayjs";

const EnhancedTableEmptyRows = ({rowsPerPage, rowHeight, headCells, disableSelecting}) => {
    const theme = useTheme()
    return <TableRow
        // hover
        disabled={true}
        // role="checkbox"
        // tabIndex={-1}
        style={{height: rowsPerPage[0] * rowHeight}}
    >
        <TableCell colSpan={headCells.length + (disableSelecting ? 0 : 1)} align={"center"}>
            <Typography variant={"table"}>
                No results matched your search.
            </Typography>
        </TableCell>
    </TableRow>

};

export default EnhancedTableEmptyRows;