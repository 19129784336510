import React, {useCallback, useState} from 'react';
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {MAROPOST_ID} from "../../../../utils/constants";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {espIntegrationService} from "../../../../api/services/espIntegrationService";
import {useDispatch} from "react-redux";
import {setSnack} from "../../../../store/actions/snack";
import CustomModal from "../../../CustomModal/CustomModal";
import CustomModalHeader from "../../../CustomModal/CustomModalHeader";
import CustomModalBody from "../../../CustomModal/CustomModalBody";
import CustomAutocompleteSelectField from "../../../CustomAutocompleteSelectField";
import ControlledTextField from "../../../ControlledTextField";
import MaropostIntegration from "../MaropostIntegration";
import CustomModalFooter from "../../../CustomModal/CustomModalFooter";
import SubmitButton from "../../../SubmitButton";

let resolver = {
    name: yup.string().required('Required field'),
    api_key: yup.string().required('Required field').min(1, 'Required'),
    default_account_address: yup.string().required('Required field')
}

const maropostResolver = {
    maropost_external_account_id: yup.string().required('Required field')
}

const maropostDefaultValues = {
    maropost_external_account_id: ""
}

const EditEspIntegrationModal = ({item, handleClose, handleRefreshItems, open}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    let defaultValues = {
        api_key: item.api_key,
        name: item.name,
        default_account_address: item.default_account_address
    }

    if (item.esp.id === MAROPOST_ID) {
        resolver = {
            ...resolver,
            ...maropostResolver
        }

        maropostDefaultValues.maropost_external_account_id = item.maropost_external_account_id

        defaultValues = {
            ...defaultValues,
            ...maropostDefaultValues
        }
    }

    const {
        formState,
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(yup.object().shape(resolver)),
        defaultValues: defaultValues
    })

    const onSuccess = useCallback((data) => {
        setLoading(true)

        espIntegrationService.updateItem(
            data,
            item.id
        )
            .then((response) => {
                dispatch(setSnack(response.message, "success"))
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                dispatch(setSnack(error.message, "error"))
                setLoading(false)
            })
    }, [item])


    return (
        <CustomModal
            open={open}
            component={"form"}
            onSubmit={handleSubmit(onSuccess)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit ESP Integration"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>

                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"api_key"}
                                             label={"API KEY"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"default_account_address"}
                                             label={"Campaign Default Address"}/>
                    </Grid>
                    {
                        item.esp.id === MAROPOST_ID && <MaropostIntegration control={control}/>
                    }
                </Grid>
            </CustomModalBody>

            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Integration"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    )
};

export default EditEspIntegrationModal;