import React from 'react';
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import {routes} from "../../utils/routes";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import useModal from "../../hooks/useModal";

const AutomatedSchedulingButton = ({automatedSchedulingAPI: {automatedSchedulingAPI, handleRefreshCampaigns}}) => {
    const dispatch = useDispatch()

    const {
        handleModalClose,
        handleModalOpen,
        modalOpen
    } = useModal()

    const handleSubmit = () => {

        automatedSchedulingAPI.makeRequest(
            null,
            (response) => {
                dispatch(setSnack(response.message, "success"))
                handleModalClose()
                handleRefreshCampaigns()
            }
        )
    }


    return (
        <>
            <Button color={"warning"} onClick={() => handleModalOpen()} sx={{}} variant={"contained"}>
                Recreate Campaigns
            </Button>
            {modalOpen &&
                <ConfirmationModal
                    title={"Recreate Campaigns"}
                    description={`Are you sure you want to recreate campaigns automatically?`}
                    handleClose={handleModalClose}
                    handleSubmit={handleSubmit}
                    loading={automatedSchedulingAPI.loading}
                    open={true}
                />
            }
        </>
    );
};

export default AutomatedSchedulingButton;