import React from 'react';
import FormHelperText from "../../FormHelperText";

const TemplateModalHelperText = () => {
    return (
        <FormHelperText>
            Use <b>{"{{logo}}"}</b> as logo url for images and <b>{"{{content}}"}</b> as replacement for content body.
        </FormHelperText>
    );
};

export default TemplateModalHelperText;