import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const automaticSchedule = () => {
        return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.post(endpoints.automatedScheduling)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const removeAutomaticallySelectedCampaigns = () => {
        return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.automatedScheduling)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const automatedSchedulingService = {
    automaticSchedule,
    removeAutomaticallySelectedCampaigns
}