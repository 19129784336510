import { useDispatch } from "react-redux";
import usePaginatedData from "./usePaginatedData";
import { useState } from "react";
import { mailingListService } from "../api/services/mailingListService";
import { setSnack } from "../store/actions/snack";

const useMailingList = (
    initialFilters = {
        name: "",
        campaign_tag: "",
        partners: [],
        verticals: [],
        brands: [],
        is_active: null
    }
) => {
    const dispatch = useDispatch();

    const [mailingLists, setMailingLists] = usePaginatedData();

    const [filters, setFilters] = useState(initialFilters);

    const [sort, setSort] = useState({
        sortType: "asc",
        sortBy: "id"
    });

    const [loadingMailingLists, setLoadingMailingLists] = useState(true);

    const fetchMailingLists = (
        {
            itemsPerPage = mailingLists.per_page,
            currentPage = mailingLists.page,
            sortType = sort.sortType,
            sortBy = sort.sortBy,
            paginate = true,
            name = filters.name,
            campaign_tag = filters.campaign_tag,
            partners = filters.partners,
            verticals = filters.verticals,
            brands = filters.brands,
        } = {}
    ) => {
        const brand_ids = brands.map((option) => option.value)
        const vertical_ids = verticals.map((option) => option.value)
        const partner_ids = partners.map((option) => option.value)
        setLoadingMailingLists(true);

        mailingListService.fetchAllMailingLists(
            itemsPerPage,
            currentPage,
            sortType,
            sortBy,
            paginate,
            name,
            campaign_tag,
            partner_ids,
            vertical_ids,
            brand_ids
        )
            .then(response => {
                setLoadingMailingLists(false);
                setMailingLists(response.data);
            })
            .catch(errorResponse => {
                setLoadingMailingLists(false);
                dispatch(setSnack(errorResponse.message, "error"));
            });
    };

    return {
        mailingLists,
        setMailingLists,
        filters,
        setFilters,
        sort,
        setSort,
        loadingMailingLists,
        fetchMailingLists
    };
};

export default useMailingList;
