
import {Button} from "@mui/material";


const AddItemButton = ({handleModalOpen, text}) => {

    return (
            <Button onClick={() => handleModalOpen()} sx={{}} variant={"contained"}>
                {text}
            </Button>
    );
};

export default AddItemButton;