import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import useResource from "../hooks/useResource";
import useModal from "../hooks/useModal";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import AddNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/AddNetworkIntegrationModal";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {espIntegrationService} from "../api/services/espIntegrationService";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import AddEspIntegrationModal
    from "../components/EspIntegration/EspIntegrationModal/AddEspIntegrationModal/AddEspIntegrationModal";
import {espService} from "../api/services/espService";
import EditEspIntegrationModal
    from "../components/EspIntegration/EspIntegrationModal/EditEspIntegrationModal/EditEspIntegrationModal";
import useDeleteMultipleItems from "../hooks/useDeleteMultipleItems";
import {categoryService} from "../api/services/categoryService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {offerService} from "../api/services/offerService";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import {contentService} from "../api/services/contentService";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import useApi from "../hooks/useApi";
import EditContentModal from "../components/Content/ContentModal/EditContentModal";
import AddContentModal from "../components/Content/ContentModal/AddContentModal";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";


const EspIntegrations = () => {
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        espIntegrationService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            esp_id: {
                label: "ESP",
                apiCall: espService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {},
        urlParams.queryParams
    )

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        espIntegrationService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        espIntegrationService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        espIntegrationService.getItems,
        urlParams.queryParams
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value},
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'esp.name',
            numeric: false,
            disablePadding: false,
            label: 'ESP',
            nested: true,
            width: "15%"
        },
        {
            id: 'api_key',
            numeric: false,
            disablePadding: false,
            label: 'API Key',
            width: "25%"
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    return <>
        <Box>
            <CustomAppBar
                handleModalOpen={handleOpenCreateItemModal}
                addButtonText={"Create ESP Integration"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditEspIntegrationModal
                open={true}
                item={updateItemData}
                handleClose={handleCloseEditItemModal}
                handleRefreshItems={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddEspIntegrationModal
                handleClose={handleCloseCreateItemModal}
                handleRefreshItems={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete ESP Integration"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> ESP Integration?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete ESP Integration"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> ESP Integration(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
}

export default EspIntegrations