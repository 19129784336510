import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            name: args.name
        }

        apiClient.get(endpoints.esp, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then((response) => {
            resolve(response.data)
        }).catch((errorResponse) => {
            reject(errorResponse.data)
        })

    })
}

export const espService = {
    getItems,

}