import React from 'react';
import {Button} from "@mui/material";

const ToggleCodeButton = ({viewCode, onChange}) => {
    return (
        <Button
            variant={"outlined"}
            onClick={onChange}
        >
            {
                viewCode ? "Show Preview" : " Show Code"
            }
        </Button>
    );
};

export default ToggleCodeButton;