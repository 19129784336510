import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {verticalService} from "../../../api/services/verticalService";
import ToggleCodeButton from "../../Content/ToggleCodeButton";
import CodeArea from "../../Content/CodeArea";
import ContentCodeArea from "../../Content/ContentCodeArea";
import ContentPreview from "../../Content/ContentPreview";
import {tagService} from "../../../api/services/tagService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModal from "../../CustomModal/CustomModal";

const schema = yup.object().shape({
    name: yup.string().required('Required field').min(1, 'Required'),
    symbol: yup.string().required('Required field').min(1, 'Required'),
    content: yup.string().required('Required field').min(1, 'Required'),
});

const AddTagModel = ({setSnack, open, handleClose, handleRefreshItems}) => {
    const [loading, setLoading] = useState(false)
    const [viewCode, setViewCode] = useState(true)
    const {
        control,
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            symbol: "",
            content: ""
        }
    })
    const onSuccess = async (data) => {
        setLoading(true)

        const response = await tagService.createItem(
            data.name,
            data.symbol,
            data.content
        )

        if (response) {
            setSnack(response.message, "success")
            setLoading(false)
            handleClose()
            handleRefreshItems()
        } else {
            setSnack("Invalid Parameters", "error")
            setLoading(false)
        }
    }

    const toggleViewCode = () => {
        setViewCode(!viewCode)
    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Tag"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledTextField control={control} name={"symbol"} label={"Symbol"}/>
                    </Grid>
                    <Grid item xs={4}>
                        <ToggleCodeButton
                            viewCode={viewCode}
                            onChange={toggleViewCode}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CodeArea style={{height: 300}}>
                            {
                                viewCode ?
                                    <ContentCodeArea
                                        control={control}
                                        name={"content"}
                                        setValue={setValue}
                                    /> :
                                    <ContentPreview control={control} name={"content"}/>
                            }
                        </CodeArea>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create Tag"}
                        loading={loading}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddTagModel);