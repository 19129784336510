import React, {useEffect, useState} from 'react';
import useMailingLists from "../../../hooks/useMailingLists";
import useMailingListsGroups from "../../../hooks/useMailingListsGroups";
import FullPageLoader from "../../Loaders/FullPageLoader";
import {Box, Button, Grid, Typography} from "@mui/material";
import CenteredLoader from "../../Loaders/CenteredLoader";
import CustomAutocompleteSelectField from "../../CustomAutocompleteSelectField";
import {mailingListService} from "../../../api/services/mailingListService";
import {mailingListGroupItemService} from "../../../api/services/mailingListGroupItemService";
import * as yup from "yup";
import {option} from "../../../utils/selectFIeldHelpers";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import SubmitButton from "../../SubmitButton";
import {setSnack} from "../../../store/actions/snack";
import {connect} from "react-redux";
import MailingListGroupAssociationTable from "./MailingListGroupAssociationTable";
import useMailingListsGroupsAssociations from "../../../hooks/useMailingListsGroupsAssociation";

const schema = yup.object().shape({
    mailing_list: option.required('Required field'),
});

const MailingListsGroupsAssociation = ({mailingListGroupId, setSnack}) => {

    const [loading, setLoading] = useState(false)

    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            mailing_list: null,
        }
    });

    const {
        mailingLists,
        loadingMailingLists,
        fetchMailingLists
    } = useMailingLists({
        brands: [],
        is_active: true,
        campaign_tag: "",
        name: "",
        partners: [],
        verticals: []
    })

    const {
        mailingListsGroupsAssociations,
        filters,
        setFilters,
        fetchMailingListsGroupsAssociations,
    } = useMailingListsGroupsAssociations(mailingListGroupId)

    useEffect(() => {
        fetchMailingListsGroupsAssociations()
        fetchMailingLists({paginate: false})
    }, []);

    if (loadingMailingLists) {
        return <CenteredLoader/>;
    }


    const mailingListOptions = mailingLists.items.map((mailingList) => {
        return {
            key: mailingList.name,
            value: mailingList.id
        }
    })

    const onSuccess = (data) => {
        setLoading(true)
        // console.debug(data)
        mailingListGroupItemService.createMailingListsGroupsAssociation(
            data.mailing_list.value,
            mailingListGroupId
        )
            .then((response) => {
                setSnack(response.message, "success");
                setLoading(false);
                reset();
                fetchMailingListsGroupsAssociations();
            })
            .catch((error) => {
                setSnack(error.message, "error");
                setLoading(false);
            });
    }

    const onError = (error) => {
        console.debug(error)
    }

    const handleDelete = (id) => {
        // console.debug(data)
        mailingListGroupItemService.deleteMailingListsGroupsAssociation(
            id
        )
            .then((response) => {
                setSnack(response.message, "success");
                fetchMailingListsGroupsAssociations();
            })
            .catch((error) => {
                setSnack(error.message, "error");
            });
    }

    return (
        <Box>
            {/*<Typography variant={"h5"}>*/}
            {/*    Lists*/}
            {/*</Typography>*/}
            <Grid container>
                <Grid item xs={12} container alignItems={"center"} spacing={2}>
                    <Grid item xs={8}>

                        <ControlledAutocompleteSelectField
                            control={control}
                            name={"mailing_list"}
                            label={"List"}
                            options={mailingListOptions}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SubmitButton
                            type={"button"}
                            onSubmit={handleSubmit(onSuccess)}
                            variant={"contained"}
                            text={"Add List"}
                            loading={loading}
                        />

                    </Grid>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    Filter*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <MailingListGroupAssociationTable
                        mailingListsGroupsAssociations={mailingListsGroupsAssociations.items}
                        pagination={mailingListsGroupsAssociations}
                        mailingLists={mailingLists.items}
                        fetchData={fetchMailingListsGroupsAssociations}
                        handleDelete={handleDelete}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(MailingListsGroupsAssociation);