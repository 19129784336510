import * as actionTypes from './actionTypes'
import {authService} from "../../api/services/authService";
import jwt_decode from "jwt-decode"
import {setSnack} from "./snack";
import {routes} from "../../utils/routes";


export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginFailure = () => {
    return {
        type: actionTypes.LOGIN_FAILURE,
    }
}

export const loginSuccess = (token) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        token,
    };
};

const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
}

export const logoutAction = () => {
    return dispatch => {
        localStorage.removeItem('token');
        // dispatch(setAuthRedirectPath(routes.homePage))
        dispatch(logout())
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

const finishInitialAuth = () => {

    return {
        type: actionTypes.FINISH_INITIAL_AUTH
    };
}

export const loginAction = ({email, password}) => {
    return dispatch => {
        dispatch(loginStart())
        authService.login(email, password)
            .then((response) => {
                const token = response.data.access_token

                localStorage.setItem('token', token);

                dispatch(loginSuccess(token))
            })
            .catch((errorResponse) => {


                dispatch(setSnack(errorResponse.message, "error"))
                dispatch(loginFailure())

            })
    };
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        // dispatch(loginStart())
        if (token) {
            const tokenData = jwt_decode(token)
            const expirationDate = new Date(tokenData.exp * 1000);
            if (expirationDate <= new Date()) {
                console.debug("expired token")
                dispatch(logoutAction());
            } else {
                dispatch(loginSuccess(token))
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
        dispatch(finishInitialAuth())
    };
};