import {useState, useCallback} from 'react';
import useApi from '../hooks/useApi';
import {useDispatch} from 'react-redux';
import {setSnack} from '../store/actions/snack';

const useUnscheduleMultipleData = (apiCall, selectedItems, handleRefreshItems, setSelectedItems) => {
    const dispatch = useDispatch();
    const [unscheduleItems, setUnscheduleMultipleItems] = useState(null);
    const unscheduleItemsApi = useApi(apiCall);

    const handleOpenUnscheduleMultipleItemsModal = () => setUnscheduleMultipleItems(true);
    const handleCloseUnscheduleMultipleItemsModal = () => setUnscheduleMultipleItems(null);

    const handleUnscheduleMultipleItems = useCallback(() => {
        unscheduleItemsApi.makeRequest(
            selectedItems,
            (response) => {
                handleCloseUnscheduleMultipleItemsModal();
                handleRefreshItems()
                setSelectedItems({})
                dispatch(setSnack(response.message, "success"));
            },
            (error) => {
                dispatch(setSnack(error.message, "error"));
            }
        );
    }, [selectedItems]);

    return {
        unscheduleItems,
        handleOpenUnscheduleMultipleItemsModal,
        handleCloseUnscheduleMultipleItemsModal,
        handleUnscheduleMultipleItems,
        unscheduleItemsApi
    };
};

export default useUnscheduleMultipleData;
