import {useCallback, useEffect, useState, useMemo} from 'react';
import {filtersElementsTypes} from "../utils/constants";

const useFilters = (defaultFilters = {}) => {
    const [values, setValues] = useState(Object.keys(defaultFilters).reduce((acc, key) => {
        acc[key] = defaultFilters[key].defaultValue;
        return acc;
    }, {})
    )

    const fieldsConfigs =defaultFilters
    const handleChangeFilters = useCallback((newFilters) => {
        setValues(prev => {
            const updatedFilters = {...prev};
            Object.keys(newFilters).forEach(filterName => {
                updatedFilters[filterName] = newFilters[filterName];
            });
            return updatedFilters;
        });
    }, []);

    const [primaryFilter, activeFiltersNo, additionalFilters] = useMemo(() => {
        let activeFiltersNumberTemp = 0;
        let primaryFilterTemp;
        let additionalFiltersTemp = 0;

        Object.entries(values).forEach(([key, value]) => {
            if (!primaryFilterTemp) {
                primaryFilterTemp = key;
            }
            if (key !== primaryFilterTemp) {
                if (!!value && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    activeFiltersNumberTemp++;
                }
                additionalFiltersTemp++;
            }
        });

        return [primaryFilterTemp, activeFiltersNumberTemp, additionalFiltersTemp]
    }, [values])

    return {values, fieldsConfigs, handleChangeFilters, activeFiltersNo, primaryFilter, additionalFilters};
};

export default useFilters;
