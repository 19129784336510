import React from 'react';
import {Alert, Typography} from "@mui/material";

const ManualScheduleHelperModalDescription = ({noOfItems}) => {
    return (
        <Alert icon={false} severity={"info"}>
            <Typography variant={"tableHighlight"}>
                {noOfItems} List(s) Selected
            </Typography>
        </Alert>
    );
};

export default ManualScheduleHelperModalDescription;