import React from 'react';
import {Box, Typography} from "@mui/material";

const FormHelperText = (params) => {
    return (
        <Box sx={{pb: 2}}>
            <Typography  variant={"label"} {...params}/>

        </Box>
    );
};

export default FormHelperText;