import React, {useCallback, useState} from 'react';
import CustomSwitch from "../CustomSwitch";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import OfferStatusChangeModal from "./OfferStatusChangeModal";
import {offerService} from "../../api/services/offerService";

const OfferStatus = ({offer, setSnack}) => {

    const [tempState, setTempState] = useState(offer.is_active)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleSuccess = useCallback((data) => {
        const new_status = !tempState
        setLoading(true)
        offerService.updateOfferStatus(offer.id, new_status, data.reason)
            .then(response => {
                setLoading(false)
                setSnack(response.message, "success")
                handleModalClose()
                setTempState(new_status)
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })
    }, [tempState])

    return (
        <>
            <CustomSwitch sx={{m: 1}} checked={tempState} onChange={handleModalOpen}/>
            {
                modalOpen &&
                <OfferStatusChangeModal
                    handleClose={handleModalClose}
                    handleSuccess={handleSuccess}
                    loading={loading}
                />
            }
        </>


    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(OfferStatus);