import React, {Component, forwardRef} from 'react';
import {Box} from "@mui/material";

const Status = forwardRef(({ size, color, ...otherProps }, ref) => (
    <Box
        ref={ref}
        sx={{
            width: size,
            height: size,
            borderRadius: size / 2,
            backgroundColor: color,
        }}
        {...otherProps}
    />
));


export default Status;