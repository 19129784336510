import {TextField} from "@mui/material";
import React from "react";

const CustomTextField = ({value, setValue, label, sx = {}, ...props}) => {
    return (
        <TextField
            label={label}
            value={value}
            fullWidth
            variant={"standard"}
            onChange={setValue}
            sx={sx}
            {...props}
        />
    );
};

export default CustomTextField;