import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        if (args.mailing_lists_group_id) {
            data.mailing_lists_group_id = args.mailing_lists_group_id.map(option => option.value)
        }

        apiClient.get(endpoints.mailingListsGroup, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const createItem = (name) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
        };

        apiClient.post(endpoints.mailingListsGroup, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateItem = (mailing_lists_group_id, name) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            name,
        };

        apiClient.patch(endpoints.mailingListsGroup + "/" + mailing_lists_group_id, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(endpoints.mailingListsGroup + "/" + itemId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const fetchJoinedMailingListsGroups = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        apiClient.get(endpoints.joinedMailingListGroups, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.mailingListsGroup + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const mailingListGroupService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    fetchJoinedMailingListsGroups,
    deleteItems
};
