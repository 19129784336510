import React from 'react';
import {Controller} from "react-hook-form";
import CustomDatePicker from "./CustomDatePicker";

const ControlledDatePicker = ({control, name, label, inputProps = null, disabled = false, disableFuture = false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (

                <CustomDatePicker
                    value={value}
                    onChange={onChange}
                    label={label}
                    disableFuture={disableFuture}
                />
            )}
        />
    );
};

export default ControlledDatePicker;