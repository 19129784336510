import React from 'react';
import {Box} from "@mui/material";
import CenteredLoader from "../Loaders/CenteredLoader";

const CodeArea = ({ children, style, loading = false }) => {
    const defaultStyle = {
        height: 570,
        overflow: "auto",
        fontSize: 12,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        backgroundColor: "#f5f5f5",
        position: 'relative', // Added for positioning the loader
    };

    // Style to hide children content visually but keep in the DOM
    const hiddenStyle = {
        visibility: 'hidden',
    };

    return (
        <Box style={{ ...defaultStyle, ...style }}>
            {/* Children are always rendered but hidden when loading */}
            <div style={{
                height: "100%",
                visibility: loading ? "hidden" : "visible"
            }}>
                {children}
            </div>
            {/* Loader is only shown when loading */}
            {loading && (
                <Box style={{
                    position: 'absolute', // Absolute positioning inside the relative parent
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <CenteredLoader />
                </Box>
            )}
        </Box>
    );
};

export default CodeArea;