import React, {useState} from 'react';
import {Box, Grid, IconButton, styled, Typography} from "@mui/material";
import {Close, Delete, Edit, Refresh} from "@mui/icons-material";
import ConfirmationModal from "./ConfirmationModal";
import useApi from "../hooks/useApi";
import {setSnack} from "../store/actions/snack";
import {useDispatch} from "react-redux";
import CenteredLoader from "./Loaders/CenteredLoader";

const CustomIconButton = styled(IconButton)(({theme}) => ({
    padding: 0,
    borderRadius: "4px",
    color: theme.palette.primary.main, // Use primary color from theme
    backgroundColor: theme.palette.common.white, // Use white color from theme
    '&:hover': {
        backgroundColor: theme.palette.action.hover, // Use hover color from theme
    },
}));

const MultipleSelectButton = (
    {
        onClearSelectedItems = null,
        selectedItems = {},
        onDeleteMultipleItems = null,
        onRetrySelectedItems = null,
        alwaysVisible = false,
        loading = false,
        icon = <Delete/>
    }
) => {
    const selectedItemsLength = Object.keys(selectedItems).length
    if (loading) icon = <CenteredLoader loaderProps={{size: 24}}/>
    if (selectedItemsLength < 1 && !alwaysVisible)
        return null


    return (
        <Box
            sx={{
                p: "8px",
                backgroundColor: "primary.light",
                borderRadius: "8px",
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Typography variant="alerts" sx={{mr: 2, flexShrink: 0}}>
                {selectedItemsLength} Item(s) Selected
            </Typography>
            <Box sx={{flexGrow: 1}}/> {/* This Box will take up the remaining space */}
            {(!onClearSelectedItems || !selectedItemsLength) ? null : <CustomIconButton
                sx={{mx: 2}}
                onClick={() => onClearSelectedItems()}
            >
                <Close/>
            </CustomIconButton>}
            {(!onRetrySelectedItems) ? null : <CustomIconButton
                sx={{mx: 2}}
                onClick={() => onRetrySelectedItems()}
            >
                <Refresh/>
            </CustomIconButton>}
            <CustomIconButton
                aria-label="delete"
                onClick={() => onDeleteMultipleItems()}
                disabled={!!loading}
            >
                {icon}
            </CustomIconButton>
        </Box>
    );
};

export default MultipleSelectButton;