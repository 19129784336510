import React from 'react';
import useApi from "../../hooks/useApi";
import {campaignService} from "../../api/services/campaignService";
import {useDispatch} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import ConfirmationModal from "../ConfirmationModal";
import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";
import useModal from "../../hooks/useModal";
import moment from 'moment-timezone';
import {MAROPOST_ID} from "../../utils/constants";

const UnscheduleCampaignButton = ({item, onSuccess}) => {
    const dispatch = useDispatch();
    const confirmationModal = useModal();

    const api = useApi(campaignService.unscheduleItem);

    const handleSubmit = () => {
        api.makeRequest(
            item.id,
            (response) => {
                dispatch(setSnack(response.message, "success"));
                confirmationModal.handleModalClose();
                onSuccess();
            },
            (error) => {
                dispatch(setSnack(error.message, "error"));
            }
        );
    };

    function hasSendTimePassed() {
        let timezone = "Europe/London";
        const {send_time, esp_integration} = item;

        if (esp_integration.esp_id === MAROPOST_ID) {
            timezone = "America/New_York";
        }

        // Parse the send_time in the specified timezone
        const sendTime = moment.tz(send_time, timezone);

        // Get the current time in the specified timezone
        const currentTime = moment().tz(timezone);

        // Check if send_time has passed
        return currentTime.isAfter(sendTime);
    }

    const isDisabled = (item.status === 0 && hasSendTimePassed());


    const modal = (
        <ConfirmationModal
            title={"Delete Campaign"}
            description={"Are you sure you want to delete and unschedule campaign?"}
            handleClose={confirmationModal.handleModalClose}
            handleSubmit={handleSubmit}
            open={confirmationModal.modalOpen}
            loading={api.loading}
        />
    );
    console.debug(hasSendTimePassed());
    return (
        <>
            <IconButton onClick={() => confirmationModal.handleModalOpen()}
                        disabled={isDisabled}>
                <Delete/>
            </IconButton>
            {confirmationModal.modalOpen && modal}
        </>
    );
};

export default UnscheduleCampaignButton;
