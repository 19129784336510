import React, {useState} from 'react';
import {Box} from "@mui/material";
import CenteredLoader from "./Loaders/CenteredLoader";
import {BrokenImage} from "@mui/icons-material";

const ExternalImage = ({imageSource, width, height, sx}) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const onImageLoad = () => {
        setLoading(false)
        setError(false)
    }

    const onError = () => {
        setLoading(false)
        setError(true)
    }

    return (
        <>
            <Box
                component={"img"}
                src={imageSource}
                alt="Event Image"
                sx={{
                    width: width,
                    height: height,
                    borderRadius: 2,
                    display: loading || error ? 'none' : "block",
                    objectFit: "scale-down",
                    ...sx
                }}
                onLoad={onImageLoad}
                onError={onError}
            />
            <Box
                sx={{
                    width: width,
                    height: height,
                    borderRadius: 2,
                    display: loading ? 'block' : "none"
                }}
            >
                <CenteredLoader/>
            </Box>
            <Box
                sx={{
                    width: width,
                    height: height,
                    borderRadius: 2,
                    position: "relative",
                    display: error ? 'block' : "none",
                    objectFit: "scale-down",
                    ...sx
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%);",
                    }}
                >
                    <BrokenImage style={{fontSize: 60}}/>
                </Box>
            </Box>
        </>
    );
};

export default ExternalImage;