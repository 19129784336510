import React, {useCallback} from 'react';
import {Box, Button, CircularProgress, Step, StepLabel, Stepper, Typography} from "@mui/material";
import SubmitButton from "../components/SubmitButton";

const steps = [
    'Pick List Groups',
    'Pick Lists',
    'Settings'
];


const useManualScheduleStepper = (
    {
        handleClose,
        handleComplete,
        selectedMailingListGroups,
        selectedMailingLists,
        loading
    }
) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const settingsDisabled = useCallback(() => {
        return !Object.keys(selectedMailingLists).length
    }, [selectedMailingLists])

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isFirstStep = () => {
        return activeStep === 0;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleStep = (index) => {
        return () => {
            setActiveStep(index)
        }
    }

    const handleNext = () => {
        if (isLastStep())
            console.debug("Completed")
        else
            setActiveStep(activeStep + 1)

    };

    const stepperHTML = (
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                    <StepLabel
                        onClick={(index === totalSteps() - 1 && settingsDisabled()) ? null : handleStep(index)}
                        // disabled
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Typography color="inherit" sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                lineHeight: "18px",
                            }}>
                                STEP {index + 1}
                            </Typography>
                            {/* Active step's text should inherit the primary color */}
                            <Typography
                                variant={"alerts"}
                            >
                                {label}
                            </Typography>
                        </Box>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )

    const backButtonHTML = (
        <Button

            variant={"outlined"}
            onClick={isFirstStep() ? handleClose : handleBack}
        >
            {isFirstStep() ? "Cancel" : "Back"}
        </Button>
    )

    const nextButtonHTML = (
        <SubmitButton
            fullWidth={false}
            variant={"contained"}
            sx={{ml: 2}}
            loading={loading}
            onSubmit={isLastStep() ? handleComplete : handleNext}
            disabled={activeStep === 1 && settingsDisabled()}
            text={isLastStep() ? "Finish" : (activeStep === 0 && !Object.keys(selectedMailingListGroups).length) ? "Skip" : "Next"}
        />

    )

    return {
        stepperHTML,
        backButtonHTML,
        nextButtonHTML,
        activeStep
    }
};

export default useManualScheduleStepper;