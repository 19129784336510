import React, {useEffect, useRef, useState} from 'react';
import {Button} from "@mui/material";
import SubmitButton from "./SubmitButton";

const ButtonWithConfirmation = ({text, onConfirm, loading, color = "error"}) => {

    const [needsConfirmation, setNeedsConfirmation] = useState(false)
    const buttonRef = useRef();
    const handleClick = (needsConfirmation) => {
        if (!needsConfirmation) {
            setNeedsConfirmation(true)
        } else {
            onConfirm()
            setNeedsConfirmation(false)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setNeedsConfirmation(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <SubmitButton
            text={text}
            type={"button"}
            onSubmit={() => handleClick(needsConfirmation)}
            variant={needsConfirmation ? "contained" : "outlined"}
            color={color}
            loading={loading}
            buttonRef={buttonRef}
        />
    );
};

export default ButtonWithConfirmation;