import {useLocation, useNavigate} from "react-router-dom";
import {simplifyParams, transformParams} from "../utils/formatText";
import queryString from "query-string";
import {useEffect, useState} from "react";

const useUrlParams = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = transformParams(queryString.parse(location.search))

    useEffect(() => {
        console.debug(queryParams)
    }, [])


    const updateUrlParams = (
        newFilters,
        newPagination,
        newSort,
        additionalParams = {},
    ) => {
        let replace = true
        // if (newPagination.paginationInfo.page === -1) {
        //     replace = true
        // }
        const params = {
            ...newFilters.values,
            ...newPagination.values,
            ...newSort.values,
            ...additionalParams
        };
        const options = {
            search: queryString.stringify(simplifyParams(params))
        };
        if (replace) {
            navigate(options, {replace: true});
        } else {
            navigate(options);
        }
    };


    return {
        queryParams,
        updateUrlParams,
    }
}

export default useUrlParams