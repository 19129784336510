import {useState, useCallback} from 'react';
import useApi from '../hooks/useApi';
import {useDispatch} from 'react-redux';
import {setSnack} from '../store/actions/snack';

const useDeleteData = (apiCall, handleRefreshItems, handleSelectItem = null) => {
    const dispatch = useDispatch();
    const [deleteItem, setDeleteItem] = useState(null);
    const deleteItemApi = useApi(apiCall);

    const handleOpenDeleteItemModal = (item) => setDeleteItem(item);
    const handleCloseDeleteItemModal = () => setDeleteItem(null);



    const handleConfirmDeleteItem = useCallback((event) => {
        // console.debug(event.target.value)
        if (deleteItem) {
            deleteItemApi.makeRequest(
                {itemId: deleteItem.id},
                (response) => {
                    handleCloseDeleteItemModal()
                    handleRefreshItems()
                    if (handleSelectItem)
                        handleSelectItem([deleteItem], false)
                },
                (error) => {
                    dispatch(setSnack(error.message, "error"))
                }
            )
        }
    }, [deleteItem])

    const handleDeleteItem = (item) => {
        if (item?.id) {
            deleteItemApi.makeRequest(
                {itemId: item.id},
                (response) => {
                    handleRefreshItems()
                },
                (error) => {
                    dispatch(setSnack(error.message, "error"))
                }
            )
        }
    }

    return {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
        handleDeleteItem
    };
};

export default useDeleteData;
