import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {categoryService} from "../../../api/services/categoryService";
import DeleteNetworkIntegration from "../../NetworkIntegration/DeleteNetworkIntegration";
import DeleteCategory from "../DeleteCategory";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";

const schema = yup.object().shape({
    name: yup.string().required('Required field').min(1, 'Required'),
});

const EditCategoryModal = (
    {
        setSnack,
        handleClose,
        handleRefreshItems,
        category
    }
) => {
    const [loading, setLoading] = useState(false)
    const {
        formState,
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: category.name
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        categoryService.updateItem(data.name, category.id)
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={true}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Category"}/>
            <CustomModalBody>

                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Category"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    )
        ;
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditCategoryModal);