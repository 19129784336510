import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledAutocompleteSelectField from "./ControlledAutocompleteSelectField";
import ControlledTextField from "./ControlledTextField";
import SubmitButton from "./SubmitButton";
import React from "react";
import CustomModalHeader from "./CustomModal/CustomModalHeader";
import CustomModal from "./CustomModal/CustomModal";
import CustomModalFooter from "./CustomModal/CustomModalFooter";
import CustomModalBody from "./CustomModal/CustomModalBody";

const ConfirmationModal = (
    {
        title,
        description,
        handleSubmit,
        handleClose,
        loading,
        open
    }
) => {
    return (
        <CustomModal open={open} handleClose={handleClose}>
            <CustomModalHeader handleClose={handleClose} title={title}/>
            <CustomModalBody>
                {description}
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Confirm"}
                        loading={loading}
                        onSubmit={handleSubmit}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    )
}

export default ConfirmationModal