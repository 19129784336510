import React from 'react';
import ButtonWithConfirmation from "../ButtonWithConfirmation";

const ContentSaveAsNewButton = ({onSaveAsNew, loading}) => {

    return (
        <ButtonWithConfirmation loading={loading} onConfirm={() => onSaveAsNew()} color={"warning"} text={"Save as New"}/>
    );
};

export default ContentSaveAsNewButton;