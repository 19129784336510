import {useState} from 'react';

const useSelectableData = (initialSelectedItems = {}) => {
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

    const handleSelectItem = (newSelectedItem, newState) => {
        const updatedItems = {...selectedItems};
        if (newState) {
            newSelectedItem.forEach(item => {
                updatedItems[item.id] = item;
            });
        } else {
            newSelectedItem.forEach(item => {
                if (updatedItems.hasOwnProperty(item.id)) {
                    delete updatedItems[item.id];
                }
            });
        }
        setSelectedItems(updatedItems);
    };

    const handleClearSelectedItems = () => {
        setSelectedItems({})
    }

    return {
        selectedItems,
        handleSelectItem,
        setSelectedItems,
        handleClearSelectedItems
    };
};

export default useSelectableData;
