import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        // console.debug(args.is_active)
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            config_name: args.config_name,
            name: args.name,
            common: args.common
        }

        if (args.mailing_list_id) {
            data.mailing_list_id = args.mailing_list_id.map(option => option.value)
        }

        apiClient.get(endpoints.automatedSchedulingConfig, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItem = (
    name,
    mailing_list_ids,
    content_lookback_days,
    excluded_offers_lookback_days,
    start_datetime,
    end_datetime,
    config_name,
    send_time,
    min_sends
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name,
            mailing_list_ids,
            excluded_offers_lookback_days,
            content_lookback_days,
            start_datetime: start_datetime.format('YYYY-MM-DDTHH:mm:ss.SSS'),
            send_time: send_time.format('HH:mm:ss.SSS'),
            min_sends,
            config_name
        }

        if (end_datetime)
            data.end_datetime = end_datetime.format('YYYY-MM-DDTHH:mm:ss.SSS')


        apiClient.post(endpoints.automatedSchedulingConfig, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (
    data,
    data_id
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const req_data = {}

        if (data.name)
            req_data.name = data.name
        if (data.mailing_list_ids)
            req_data.mailing_list_ids = data.mailing_list_ids
        if (data.content_lookback_days)
            req_data.content_lookback_days = data.content_lookback_days
        if (data.excluded_offers_lookback_days)
            req_data.excluded_offers_lookback_days = data.excluded_offers_lookback_days
        if (data.min_sends)
            req_data.min_sends = data.min_sends
        if (data.start_datetime)
            req_data.start_datetime = data.start_datetime.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (data.send_time)
            req_data.send_time = data.send_time.format('HH:mm:ss.SSS')
        if (data.end_datetime)
            req_data.end_datetime = data.end_datetime.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (data.config_name)
            req_data.config_name = data.config_name

        apiClient.patch(endpoints.automatedSchedulingConfig + "/" + data_id, req_data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItemStatus = (
    data,
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const req_data = {}

        req_data.is_active = data.is_active

        apiClient.patch(endpoints.automatedSchedulingConfig + "/" + data.id, req_data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.automatedSchedulingConfig + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const automatedSchedulingConfigService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    updateItemStatus
}