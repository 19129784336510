import React, {useState} from 'react';
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledAutocompleteSelectField from "../ControlledAutocompleteSelectField";
import ControlledTextField from "../ControlledTextField";
import SubmitButton from "../SubmitButton";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {offerService} from "../../api/services/offerService";

const schema = yup.object().shape({
    reason: yup.string().required('Required field'),
});

const OfferStatusChangeModal = ({handleClose, open, handleSuccess, loading}) => {

    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            reason: "",
        }
    })

    const onSuccess = (data) => {
        handleSuccess(data)
    }

    const onError = (error) => {

    }

    return (
        <Modal
            open={true}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
        >
            <Container maxWidth={"sm"} component={Paper} sx={{my: 4, py: 2}}>
                <form onSubmit={handleSubmit(onSuccess, onError)}>
                    <Grid container alignItems={"center"} columns={24}>
                        <Grid item xs={22} md={23}>
                            <Typography variant={"h4"} sx={{my: 2}}>
                                Changing Offer Status
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ControlledTextField
                                control={control}
                                name={"reason"}
                                label={"Reason"}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleClose()}
                                fullWidth
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <SubmitButton
                                text={"SUBMIT"}
                                loading={loading}
                            />
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </Modal>
    );
};

export default OfferStatusChangeModal;