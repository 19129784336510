import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography, useTheme} from "@mui/material";

import {Close} from "@mui/icons-material";
import CustomModal from "../CustomModal/CustomModal";
import CustomModalHeader from "../CustomModal/CustomModalHeader";
import CustomModalBody from "../CustomModal/CustomModalBody";
import CustomModalFooter from "../CustomModal/CustomModalFooter";
import {setSnack} from "../../store/actions/snack";
import {contentService} from "../../api/services/contentService";
import CodeArea from "../Content/CodeArea";
import CenteredLoader from "../Loaders/CenteredLoader";
import ContentCodeArea from "../Content/ContentCodeArea";
import ContentPreview from "../Content/ContentPreview";
import {generateSrcDocWithStyles} from "../../utils/designToken";


const schema = yup.object().shape({
    name: yup.string().required('Required field').min(1, 'Required'),
});

const ContentReportingPreviewModal = ({setSnack, open, handleClose}) => {
    const theme = useTheme();
    const [code, setCode] = useState(null)
    const [loadingCode, setLoadingCode] = useState(false)

    useEffect(() => {
        if (!!open) {
            fetchContentCode()
        }
    }, [open]);

    const onClose = () => {
        setCode(null)
        handleClose()
    }

    const getScrollbarStyles = useCallback(() => {
        const scrollbarStyles = theme.components?.MuiCssBaseline?.styleOverrides?.body || {};
        let css = '';

        // Convert JS object to CSS string
        Object.keys(scrollbarStyles).forEach(key => {
            const style = scrollbarStyles[key];
            css += `${key} {`;
            Object.keys(style).forEach(prop => {
                css += `${prop}: ${style[prop]};`;
            });
            css += `}`;
        });

        return css;
    }, [theme]);



    const fetchContentCode = () => {
        contentService.fetchItemFile(open)
            .then((response) => {
                setLoadingCode(false);
                setCode(generateSrcDocWithStyles(response.data.html))
                // setOffers(response.data);
            })
            .catch((errorResponse) => {
                setLoadingCode(false);
                setSnack(errorResponse.message, "error");
            });
    }

    return (
        <CustomModal
            open={!!open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            maxWidth={"md"}
        >
            <CustomModalHeader handleClose={onClose} title={"Content Preview"}/>
            <CustomModalBody>
                <Grid item xs={12}>
                    <CodeArea loading={loadingCode}>

                        <iframe
                            srcDoc={code}
                            title="Preview"
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '98%',

                            }}
                        />

                    </CodeArea>

                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={() => onClose()}
                        fullWidth
                        variant={"contained"}
                    >
                        Close
                    </Button>
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(ContentReportingPreviewModal);