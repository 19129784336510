import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const createItem = (name) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name
        }

        apiClient.post(endpoints.vertical, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (name, vertical_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name
        }

        apiClient.patch(endpoints.vertical + "/" + vertical_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.vertical + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
        }

        apiClient.get(endpoints.vertical, {
            params: data
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.vertical + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const verticalService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    deleteItems
}