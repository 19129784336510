import React from 'react';
import FormHelperText from "../../FormHelperText";

const OfferModalLinkHelperText = ({selectedNetworkIntegration}) => {
    return (
        <FormHelperText>
            {/* Conditionally render the helper text */}
            {selectedNetworkIntegration ? (
                <>Use <b>{selectedNetworkIntegration["network.source_field_name"]}={"{{source}}"}</b> in link parameters for
                    tracking.</>
            ) : (
                "Select a Network Integration to see specific instructions."
            )}
        </FormHelperText>
    );
};

export default OfferModalLinkHelperText;