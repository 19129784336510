
import {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import getDesignTokens from "./utils/designToken";
import Snack from "./components/Snack";
import {checkAuthState} from "./store/actions/auth";
import {connect} from "react-redux";
import PrimaryNavigation from "./navigation/primaryNavigation";
import FullPageLoader from "./components/Loaders/FullPageLoader";

const App = ({checkAuthState, isInitialAuthFinished}) => {
    const [mode] = useState("light")
    const theme = createTheme(getDesignTokens(mode))


    useEffect(() => {
        checkAuthState()
        console.debug(theme)
    }, [])

    return (
        <ThemeProvider theme={theme}>

            <CssBaseline/>
            {
                isInitialAuthFinished ?

                    <>
                        <PrimaryNavigation/>
                        <Snack/>
                    </>
                    :
                    <FullPageLoader/>
            }

        </ThemeProvider>
    );
};


const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        isInitialAuthFinished: state.auth.isInitialAuthFinished
    };
};


const mapDispatchToProps = dispatch => {
    return {
        checkAuthState: () => dispatch(checkAuthState()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);