import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';

const CampaignReportingDataAlertChip = (
    {
        campaignData,
        targetFieldName,
        controlFieldName,
        isGreen,
        isYellow,
        getText
    }
) => {
    const theme = useTheme()

    let backgroundColor = "rgba(0, 0, 0, 0)";
    let color = theme.palette.text.primary
    let targetValue = campaignData[targetFieldName]
    if (!!campaignData[controlFieldName] && campaignData[controlFieldName] > 0 && targetValue) {
        if (isGreen(targetValue)) {
            backgroundColor = theme.palette.success.main
            color = theme.palette.getContrastText(backgroundColor)
        } else if (isYellow(targetValue)) {
            backgroundColor = theme.palette.warning.main
            color = theme.palette.getContrastText(backgroundColor)
        } else {
            backgroundColor = theme.palette.error.main
            color = theme.palette.getContrastText(backgroundColor)
        }
    }


    return (
        <Box> {/* Removed the background color from here */}
            <Typography
                variant="table" // Keeping your custom variant
                sx={{
                    display: "inline-block", // Change to inline-block to limit width to content
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    backgroundColor: backgroundColor,
                    p: "5px",
                    borderRadius: 2,
                    color: color
                }}
            >
                {getText(targetValue)}
            </Typography>
        </Box>
    );
};

export default CampaignReportingDataAlertChip;
