import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {option} from "../../../utils/selectFIeldHelpers";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import {configService as configsService} from "../../../api/services/configService";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {templateService} from "../../../api/services/templateService";

const schema = yup.object().shape({
    name: yup.string().required('Required field'),
    template: option.required('Required field'),
    segments: yup.string().required('Required field'),
    suppressed_segments: yup.string(),
});

const AddConfigModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems,
        mailingListId
    }
) => {
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            segments: "",
            suppressed_segments: "",
            template: null,
        }
    });

    const onSuccess = (data) => {
        setLoading(true);

        configsService.createItem(
            mailingListId,
            data.name,
            data.template.value,
            data.suppressed_segments,
            data.segments,
        )
            .then((response) => {
                setSnack(response.message, "success");
                setLoading(false);
                handleClose();
                handleRefreshItems();
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error");
                setLoading(false);
            });
    }

    const onError = (error) => {
        console.debug(error);
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Config"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledAPIAutocompleteSelectField
                            control={control} name={"template"}
                            label={"Template"}
                            apiCall={templateService.getItems}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"segments"} label={"Segments"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField control={control} name={"suppressed_segments"}
                                             label={"Suppressed Segments"}/>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create Config"}
                        loading={loading}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddConfigModal);
