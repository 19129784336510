import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (
    args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common
        }

        if (!!args.is_active) {
            data.is_active = args.is_active.value
        }

        if (!!args.available) {
            data.available = args.available
        }

        if (args.network_integration_id) {
            data.network_integration_id = args.network_integration_id.map(option => option.value)
        }

        if (args.category_id) {
            data.category_id = args.category_id.map(option => option.value)
        }

        if (args.offer_id) {
            data.offer_id = args.offer_id.map(option => option.value)
        }


        apiClient.get(endpoints.offer, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItem = (name, link, network_integration_id, category_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name,
            link,
            network_integration_id,
            category_id
        }

        apiClient.post(endpoints.offer, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItemStatus = ({id, is_active}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            is_active,
        }

        apiClient.patch(`${endpoints.offer}/${id}`, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const updateItem = (
    offer_id,
    name,
    link,
    network_integration_id,
    category_id,
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            name,
            link,
            network_integration_id,
            category_id,
        }
        console.debug(data)
        apiClient.patch(endpoints.offer + "/" + offer_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.offer + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.offer + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const offerService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    updateItemStatus,
    deleteItems
}