import React, {useState} from 'react';
import {useWatch} from "react-hook-form";
import {generateSrcDocWithStyles} from "../../utils/designToken";

const ContentPreview = ({control, name}) => {

    const content = useWatch({
        control,
        name
    })

    return (
        <iframe
            srcDoc={generateSrcDocWithStyles(content)}
            title="Preview"
            style={{
                border: 'none',
                width: '100%',
                height: '98%',

            }}
        />
    );
};

export default ContentPreview;