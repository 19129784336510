import React from 'react';
import useResource from "../../hooks/useResource";
import {mailingListGroupService} from "../../api/services/mailingListGroupService";
import {filtersElementsTypes} from "../../utils/constants";
import useTable from "../../hooks/useTable";
import EnhancedTable from "../Table/EnhancedTable";
import {Grid} from "@mui/material";
import ManualScheduleHelperModalText from "./ManualScheduleHelperModalText";
import ManualScheduleHelperSearchFilters from "./ManualScheduleHelperSearchFilters";
import ManualScheduleHelperModalContent from "./ManualScheduleHelperModalContent";
import useData from "../../hooks/useData";
import {categoryService} from "../../api/services/categoryService";
import useSelectableData from "../../hooks/useSelectableData";
import useDeleteData from "../../hooks/useDeleteData";

const ManualScheduleSelectMailingListGroups = (
    {
        handleSelectMailingListGroups,
        selectedMailingListGroups,
        description
    }
) => {
    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        mailingListGroupService.fetchJoinedMailingListsGroups,
        {
            name: {
                type: filtersElementsTypes.TEXT,
            },
        },
        {
            per_page: 5
        },
        {},
    );


    const table = useTable(items, itemsPagination, selectedMailingListGroups, handleSelectMailingListGroups)

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
    ];


    return (
        <ManualScheduleHelperModalContent>
            <Grid item xs={12}>
                <ManualScheduleHelperModalText helperText={"Pick a List Group or a List in order to see Settings"}/>
            </Grid>
            <Grid item xs={12}>
                {description}
            </Grid>
            <Grid item xs={12}>
                <ManualScheduleHelperSearchFilters filters={itemsFilters}/>
            </Grid>

            <Grid item xs={12}>
                <EnhancedTable
                    table={table}
                    headCells={headCells}
                    data={{
                        items: items,
                        sort: itemsSort,
                        pagination: itemsPagination,
                        api: fetchItemsApi
                    }}
                    rowsPerPage={[5, 25, 50]}
                    pagination={itemsPagination.values}
                    searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
                />
            </Grid>
        </ManualScheduleHelperModalContent>
    );
};

export default ManualScheduleSelectMailingListGroups;