import {useState} from "react";

const defaultSort = {
    sortOrder: "desc",
    sortBy: "updated_at"
}

const useSort = (initialSort = {}) => {
    const [values, setValues] = useState({...defaultSort, ...initialSort});

    const handleSort = (sortBy, sortOrder) => {
        setValues({
            sortOrder,
            sortBy
        })
    }

    return {values, handleSort}
}

export default useSort