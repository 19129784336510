import React from 'react';
import useApi from "../../hooks/useApi";
import {campaignService} from "../../api/services/campaignService";
import {useDispatch} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import ConfirmationModal from "../ConfirmationModal";
import CustomSwitch from "../CustomSwitch";
import {IconButton} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import useModal from "../../hooks/useModal";

const RetryCampaignButton = (
    {
        item,
        onSuccess
    }
) => {
    const dispatch = useDispatch()
    const confirmationModal = useModal()

    const api = useApi(campaignService.retryItem)

    const handleSubmit = () => {
        api.makeRequest(
            item.id,
            (response) => {
                dispatch(setSnack(response.message, "success"))
                confirmationModal.handleModalClose()
                onSuccess()
            },
            (error) => {
                dispatch(setSnack(error.message, "error"))
            }
        )
    }

    const modal = <ConfirmationModal
        title={"Reschedule Campaign"}
        description={"Are you sure you want to reschedule campaign?"}
        handleClose={confirmationModal.handleModalClose}
        handleSubmit={handleSubmit}
        open={confirmationModal.modalOpen}
        loading={api.loading}
    />

    return (
        <>
                <IconButton onClick={() => confirmationModal.handleModalOpen()} disabled={item.status <= 0}>
                    <Refresh/>
                </IconButton>
            {confirmationModal.modalOpen && modal}
        </>
    );
};

export default RetryCampaignButton