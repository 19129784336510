import useApi from "./useApi";
import {useState} from "react";

const useDataById = (apiFunc, requestIdFieldName) => {
    const [items, setItems] = useState([])
    const api = useApi(apiFunc)

    const fetchDataById = (items) => {
        api.makeRequest(
            {
                [requestIdFieldName]: items
            },
            (response) => {
                setItems(response.data.items)
            }
        )
    }

    return {
        items,
        api,
        fetchDataById
    }
}

export default useDataById;