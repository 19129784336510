const login = "/api/auth/login";
const register = "/api/auth/register";
const network = "/api/network/"
const networkIntegration = "/api/network-integration"
const offer = "/api/offer"
const content = "/api/content"
const category = "/api/category"
const maropostIntegration = "/api/esp-integration/maropost"
const espIntegration = "/api/esp-integration"
const esp = "/api/esp"
const brand = "/api/brand"
const vertical = "/api/vertical"
const tag = "/api/tag"
const template = "/api/template"
const partner = "/api/partner"
const mailingList = "/api/mailing-list"
const joinedMailingList = "/api/mailing-list/joined"
const config = "/config"
const mailingListsGroup = "/api/mailing-list-group"
const joinedMailingListGroups = "/api/mailing-list-group/joined"
const mailingListGroupItem = "/api/mailing-list-group-item"
const scheduleMultiple = "/api/campaign/schedule-multiple"
const campaign = "/api/campaign"
const contentReporting = "/api/content-reporting"
const campaignReporting = "/api/campaign-reporting"
const offerReporting = "/api/offer-reporting"
const mailingListReporting = "/api/mailing-list-reporting"
const automatedSchedulingConfig = "/api/automated-scheduling-config"
const automatedScheduling = "/api/automated-scheduling"

export const endpoints = {
    login,
    register,
    network,
    networkIntegration,
    offer,
    content,
    category,
    maropostIntegration,
    espIntegration,
    esp,
    brand,
    vertical,
    tag,
    template,
    partner,
    mailingList,
    config,
    mailingListsGroup,
    mailingListGroupItem,
    joinedMailingListGroups,
    joinedMailingList,
    scheduleMultiple,
    campaign,
    contentReporting,
    campaignReporting,
    offerReporting,
    mailingListReporting,
    automatedSchedulingConfig,
    automatedScheduling
}