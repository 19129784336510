import React, {useRef} from 'react';
import {Button, FormHelperText} from "@mui/material";

const ControlledButtonFileInput = ({control, name, setValue, formState}) => {
    const fileInput = useRef();
    const onSelectFile = (selectedFile) => {
        // setValue(name, selectedFile.target.files[0], {shouldValidate: true, shouldTouch: true, shouldDirty: true})
        const file = selectedFile.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const fileContent = event.target.result;
            setValue(name, fileContent, {shouldValidate: true, shouldTouch: true, shouldDirty: true})
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };

        reader.readAsText(file);
    }

    return (
        <>
            <Button
                fullWidth
                variant={"contained"}
                onClick={() => fileInput.current.click()}
            >
                Upload File
            </Button>
            <input
                style={{display: 'none'}}
                accept="text/*"
                id={`select-${name}`}
                type="file"
                onChange={onSelectFile}
                ref={fileInput}
            />
        </>
    );
};

export default ControlledButtonFileInput;