import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        // console.debug(args.is_active)
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            subject_line: args.subject_line,
            preheader: args.preheader,
            from_name: args.from_name,
            name: args.name,
            common: args.common
        }


        console.debug(args.is_active)

        if (args.is_active !== undefined && args.is_active !== null){
            data.is_active = args.is_active.value
        }

        if (args.offer_id) {
            data.offer_id = args.offer_id.map(option => option.value)
        }

        if (args.content_id) {
            data.content_id = args.content_id.map(option => option.value)
        }

        apiClient.get(endpoints.content, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const createItem = (
    name,
    subject_line,
    from_name,
    offer_id,
    preheader,
    content_code
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            offer_id,
            name,
            subject_line,
            from_name,
            preheader,
            content_html: content_code
        }

        apiClient.post(endpoints.content, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (
    data,
    data_id
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const req_data = {}

        if (data.content_name)
            req_data.name= data.content_name
        if (data.subject_line)
            req_data.subject_line= data.subject_line
        if (data.preheader)
            req_data.preheader= data.preheader
        if (data.from_name)
            req_data.from_name= data.from_name
        if (data.offer_id)
            req_data.offer_id= data.offer_id
        if (data.content_code)
            req_data.content_html= data.content_code

        apiClient.patch(endpoints.content + "/" + data_id, req_data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItemStatus = ({id, is_active}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            is_active,
        }

        apiClient.patch(`${endpoints.content}/${id}`, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.content + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const fetchItemFile = (contentId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.content + "/" + contentId + "/html")
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const fetchAvailableItems = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.content + "/list")
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.content + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const contentService = {
    getItems,
    createItem,
    updateItem,
    deleteItem,
    fetchItemFile,
    updateItemStatus,
    fetchAvailableItems,
    deleteItems
}