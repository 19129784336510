import React from 'react';
import {Controller} from "react-hook-form";
import {TextField} from "@mui/material";
import CustomTextField from "./CustomTextField";

const ControlledTextField = ({control, name, label, inputProps=null, type="text", helperText=null}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <CustomTextField
                    variant={"standard"}
                    label={label}
                    value={value}
                    autoComplete="off"
                    fullWidth
                    type={type}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : helperText || " "}
                    InputProps={inputProps}
                />)}
        />
    );
};

export default ControlledTextField;