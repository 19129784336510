import React from 'react';
import {styled, TableCell, tableCellClasses, TableHead, TableRow, Typography} from "@mui/material";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.customTokens.tableHighlightColor,
    },
}));

const ManualScheduleTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <StyledTableCell>
                    <Typography variant={"tableHighlight"} sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}>
                        List Name
                    </Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant={"tableHighlight"} sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}>
                        Offer
                    </Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant={"tableHighlight"} sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}>
                        Content
                    </Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant={"tableHighlight"} sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}>
                        Config
                    </Typography>
                </StyledTableCell>
                <StyledTableCell>
                    <Typography variant={"tableHighlight"} sx={{
                        display: "block",
                        width: "100%",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}>
                        Send Time
                    </Typography>
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
};

export default ManualScheduleTableHeader;