import {useEffect, useState} from 'react';
import useApi from "./useApi";
import useUrlParams from "./useUrlParams";
import {useDispatch} from "react-redux";
import {setSnack} from "../store/actions/snack";

const useUpdateData = (
    apiCall,
    queryParams = {},
    requestIdFieldName
) => {
    // const urlParams = useUrlParams()
    const dispatch = useDispatch()
    const [updateItemId, setUpdateItemId] = useState({value: parseInt(queryParams.edit)});
    const [updateItemData, setUpdateItemData] = useState(null);
    const fetchDataApi = useApi(apiCall)

    useEffect(() => {
        if (updateItemId?.value && !updateItemData) {
            console.debug(updateItemId)
            fetchDataApi.makeRequest(
                {
                    [requestIdFieldName]: [updateItemId]
                },
                (response) => {
                    console.debug(response.data)
                    if (response.data.items.length) {
                        console.debug(response.data.items[0])
                        setUpdateItemData(response.data.items[0])
                    } else {
                        dispatch(setSnack("Resource does not exist!", "error"));
                        setUpdateItemId(null)
                    }
                },
                (error) => {
                    console.debug(error)
                }
            )
        }
    }, [updateItemId])

    const handleOpenEditItemModal = (item) => {
        setUpdateItemId({value: item.id});
        setUpdateItemData(item);
    };

    const handleCloseEditItemModal = () => {
        setUpdateItemId(null);
        setUpdateItemData(null);
    };

    return {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
        fetchDataApi
    };
};

export default useUpdateData;