import { useState } from 'react';

const useAddData = () => {
    const [createItemModalOpen, setCreateItemModalOpen] = useState(false);

    const handleOpenCreateItemModal = () => setCreateItemModalOpen(true);
    const handleCloseCreateItemModal = () => setCreateItemModalOpen(false);

    return {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    };
};

export default useAddData;
