import {useCallback, useEffect, useMemo, useState} from "react";

const useTable = (items, pagination, selected={}, handleSelect = null) => {

    const handleSelectAllClick = useCallback((event) => {
        const newSelected = items.map((n) => n);
        if (handleSelect)
            handleSelect(newSelected, event.target.checked);
    }, [items, handleSelect]);

    const handleClick = (item, event) => {
        if (handleSelect)
            handleSelect([item], event.target.checked)
    };

    const allRowsChecked = useCallback(() => {
        return !!items ? items.length > 0 && items.every(item => selected.hasOwnProperty(item.id)) : false;
    }, [items, selected]);

    const isAnyItemSelected = useCallback(() => {
        return !!items ? items.some(item => selected.hasOwnProperty(item.id)) : false;
    }, [items, selected]);

    const isSelected = useCallback((item) => {
        return selected.hasOwnProperty(item.id);
    }, [selected]);

    const emptyRows = useMemo(() => {
        if (pagination.values.page > 0) {
            return Math.max(0, (1 + pagination.values.page) * pagination.values.per_page - items.length);
        }
        return 0;
    }, [pagination.values, items]);

    return {
        selected,
        handleClick,
        handleSelectAllClick,
        isSelected,
        emptyRows,
        allRowsChecked,
        isAnyItemSelected
    }
}

export default useTable;